import { useEffect } from 'react';

import { useEvent } from './useEvent';

export const useOutsideClick = (elementRef, handler, attached = true) => {
  const eventHandler = useEvent(handler);

  useEffect(() => {
    if (!attached) {
      return;
    }
    const handleClick = (e) => {
      if (!elementRef.current) {
        return;
      }

      if (e.target instanceof Node && !elementRef.current.contains(e.target)) {
        eventHandler(e);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [elementRef, eventHandler, attached]);
};
