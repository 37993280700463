import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProducts } from '../../api';

export const getProductsAction = createAsyncThunk('Products/get Products', async (_, thunkAPI) => {
  try {
    const response = await getProducts();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
