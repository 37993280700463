import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import About from './pages/About/About';
import Catalog from './components/Catalog/Catalog';
import Contacts from './pages/Contacts/Contacts';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import Cart from './pages/Cart/Cart';
import { SubCategories } from './pages/SubCategories/SubCategories';
import { Products } from './pages/Products/Products';
import Favorites from './pages/Favorites/Favorites';
import CardProduct from './pages/CardProduct/CardProduct';
import Сheckout from './pages/Сheckout/Сheckout';
import { MainOutlet, ShopOutlet } from './outlets';
import { Admin } from './pages';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { Modal } from './shared';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  // стрелка верх
  window.addEventListener('scroll', function () {
    let scroll = document.querySelector('.btn-up');
    scroll?.classList?.toggle('active', window.scrollY > 500);
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className='App'>
          <Routes>
            <Route element={<MainOutlet />}>
              <Route element={<ShopOutlet />}>
                <Route path='/' element={<HomePage />} />
                <Route path='/about' element={<About />} />

                <Route path='/catalog'>
                  <Route index element={<Catalog />} />
                  <Route path=':category'>
                    <Route index element={<SubCategories />} />
                    <Route path=':subCategory' element={<Products />} />
                  </Route>
                </Route>

                <Route path='/contacts' element={<Contacts />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='*' element={<NotFoundPage />} />
                <Route path='/favorites' element={<Favorites />} />
                <Route path='/cardProduct/:id' element={<CardProduct />} />
                <Route path='/checkout' element={<Сheckout />} />
              </Route>

              <Route path='admin' element={<Admin />} />
            </Route>
          </Routes>
        </div>

        <Modal />
      </PersistGate>
    </Provider>
  );
}

export default App;
