import React, { useState } from 'react';
import styles from './paramsBtn.module.scss';

import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { Button } from '../Button';
import { SubCategoryParameterModal } from '../SubCategoryParameterModal/SubCategoryParameterModal';
import { DeleteModal } from '../DeleteModal';
import { ModalService } from '../../shared';

export default function ParamsBtn(props) {
  const [activeBtn, setActiveBtn] = useState(false);

  function toggleParam() {
    setActiveBtn(!activeBtn);

    props.onClick(props.id);
  }

  const showEditSubCategoryParameterModal = (e) => {
    e.stopPropagation();

    ModalService.open(SubCategoryParameterModal, {
      subCategory: props.subCategory,
      defaultValues: {
        id: props.id,
        title: props.title,
      },
    });
  };

  const showDeleteSubCategoryParameterModal = (e) => {
    e.stopPropagation();

    ModalService.open(DeleteModal, {
      name: props.title,
      onSubmit: () => props.onDelete({ id: props.id, subCategoryName: props.subCategory }),
    });
  };

  return (
    <li className={styles.item}>
      <div
        onClick={toggleParam}
        className={`${activeBtn ? styles.activeBtn : ''}  ${styles.btn} btn-reset`}
      >
        {props.title}
      </div>

      {props.isAuthorized && (
        <div className={styles.controls}>
          <Button className={styles.button} onClick={showEditSubCategoryParameterModal}>
            <EditIcon className={styles.icon} />
          </Button>

          <Button className={styles.button} onClick={showDeleteSubCategoryParameterModal}>
            <DeleteIcon className={styles.icon} />
          </Button>
        </div>
      )}
    </li>
  );
}
