import React from 'react';
import styles from './BtnLike.module.scss';

export default function BtnLike({ product, isFavorite, onClikHeart }) {
  const onClickLike = () => {
    onClikHeart({
      id: product.id,
      title: product.title,
      price: product.price,
      image: product.image,
      stock: product.stock,
      count: 1,
      discount: product.discount,
    });
  };

  return (
    <button onClick={onClickLike} className={isFavorite ? styles.like__click : styles.like}>
      <svg
        width='20px'
        height='20px'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z'
          fill='#1C274C'
        />
      </svg>
    </button>
  );
}
