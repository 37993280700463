export function getPriceWithDiscount(price, discount) {
  if (!discount) {
    return price;
  }

  try {
    if (discount !== 0) {
      price = Math.floor(price * (1 - discount / 100));
    }
    return price || 0;
  } catch {
    return 0;
  }
}
