import { useSelector, useDispatch } from 'react-redux';

import { selectAuthorized } from './selectors';
import { setAuthorized } from './slice';
import { usePromiseCbWithState } from '../../hooks';
import { refreshToken } from '../../api';

export function useSelectAuthorized() {
  return useSelector(selectAuthorized);
}

export const useSetAuthorized = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(setAuthorized(payload));
};

export const useRefreshToken = () => {
  const setAuthorized = useSetAuthorized();

  return usePromiseCbWithState(async () => {
    try {
      const data = await refreshToken();
  
      if (data.data?.accessToken) {
        setAuthorized(true);
      }
    } catch (e) {
    }
  });
};
