import { useSelector, useDispatch } from 'react-redux';

import { selectCartItemById, selectCartItems } from './selectors';
import { resetCart, toggleCartItem, updateCartItem } from './slice';

export function useSelectCartItems() {
  return useSelector(selectCartItems);
}

export function useSelectCartItemById(id) {
  return useSelector(selectCartItemById(id));
}

export const useToggleCartItem = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(toggleCartItem(payload));
};

export const useUpdateCartItem = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(updateCartItem(payload));
};

export const useResetCart = () => {
  const dispatch = useDispatch();

  return () => dispatch(resetCart());
};
