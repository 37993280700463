import { combineReducers } from '@reduxjs/toolkit';

import { userReducer } from './user';
import { categoriesReducer } from './categories';
import { favoritesReducer } from './favorites';
import { cartReducer } from './cart';
import { subCategoriesReducer } from './subCategories';
import { productsReducer } from './products';

export const reducer = combineReducers({
  categoriesState: categoriesReducer,
  subCategoriesState: subCategoriesReducer,
  userState: userReducer,
  favoritesState: favoritesReducer,
  cartState: cartReducer,
  productsState: productsReducer,
});
