import { toDictionary } from '../../../shared';

export const getDefaultValues = (product, allParameters) => {
  if (!product) {
    return {};
  }

  const parameterById = toDictionary(allParameters, (p) => p.id);

  return {
    ...product,
    ...(product.characteristics && { characteristics: product.characteristics.join('\n') }),
    ...(product.peculiarities && { peculiarities: product.peculiarities.join('\n') }),
    ...(product.parameters && {
      parameters: product.parameters.reduce((res, { id }) => {
        if (parameterById[id]) {
          res.push(parameterById[id]);
        }

        return res;
      }, []),
    }),
  };
};
