import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CategoryCard.module.scss';
import { Button } from '../Button';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { CategoryModal } from '../CategoryModal';
import { ModalService } from '../../shared';
import { DeleteModal } from '../DeleteModal';

export function CategoryCard(props) {
  const showEditCategoryModal = () => {
    ModalService.open(CategoryModal, {
      defaultValues: {
        id: props.id,
        name: props.name,
        title: props.title,
        image: 'has image',
      },
      imageUrl: props.image,
    });
  };

  const showDeleteCategoryModal = () => {
    ModalService.open(DeleteModal, {
      name: props.title,
      text: 'Все подкатегории и товары в подкатегориях будут удалены',
      onSubmit: () => props.onDelete(props.id),
    });
  };

  return (
    <li className={`${styles.card} card-anim`}>
      {props.isAuthorized && (
        <div className={styles.controls}>
          <Button className={styles.button} onClick={showEditCategoryModal}>
            <EditIcon className={styles.icon} />
          </Button>

          <Button className={styles.button} onClick={showDeleteCategoryModal}>
            <DeleteIcon className={styles.icon} />
          </Button>
        </div>
      )}

      <Link className='catalog__link ' to={`/catalog/${props.name}`}>
        <div className={styles.catalog__card}>
          <img className={styles.catalog__img} src={props.image} alt='logo' />
          <div className={styles.catalog__title}>
            <h3 className={styles.h3}>{props.title}</h3>
          </div>
        </div>
      </Link>
    </li>
  );
}
