import { apiPath } from '../apiPath';
import { $api } from '../configuration';

export const createProduct = (data) => {
  return $api.post(apiPath.products, data, {
    headers: 'multipart/form-data',
  });
};

export const updateProduct = (data) => {
  return $api.put(apiPath.products, data, {
    headers: 'multipart/form-data',
  });
};

export const deleteProduct = (id) => {
  return $api.delete(`${apiPath.products}/${id}`);
};

export const getProducts = () => {
  return $api(apiPath.products);
};

export const getProductsByTitle = (title) => {
  return $api(`${apiPath.products}/title/${title}`);
};

export const getProduct = (id) => {
  return $api(`${apiPath.products}/${id}`);
};
