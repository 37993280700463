import { useSelector, useDispatch } from 'react-redux';

import { getProductsAction } from './actions';
import { selectFilteredProducts, selectProducts, selectProductsSearch } from './selectors';
import { setSearch } from './slice';

export function useSelectProducts() {
  return useSelector(selectProducts);
}

export function useSelectProductsSearch() {
  return useSelector(selectProductsSearch);
}

export function useSelectFilteredProducts() {
  return useSelector(selectFilteredProducts);
}

export const useGetProducts = () => {
  const dispatch = useDispatch();

  return () => dispatch(getProductsAction());
};

export const useChangeSearch = () => {
  const dispatch = useDispatch();

  return (search) => dispatch(setSearch(search));
};
