import { createSlice } from '@reduxjs/toolkit';

import { getProductsAction } from './actions';

const initialState = {
  products: [],
  search: '',
};

const productsSlice = createSlice({
  initialState,
  name: 'products',
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsAction.fulfilled, (state, { payload }) => {
      state.products = payload;
    });
  },
});

export const productsReducer = productsSlice.reducer;
export const { setSearch } = productsSlice.actions;
