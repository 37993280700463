import React, { useEffect } from 'react';
import { ControlledDropzone } from '../../Input/ControlledDropzone';
import classes from '../ProductModal.module.scss';
import { useDropzone } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { ErrorMessage } from '../../ErrorMessage';
import { Button } from '../../Button';

export function HeadImage({ control, setValue }) {
  const image = useWatch({ control, name: 'image' });

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (files) => {
      setValue('image', files[0], {
        shouldValidate: true,
      });
    },
  });

  return (
    <>
      <ControlledDropzone
        label='Картинка'
        labelClass={classes.label}
        name='image'
        control={control}
        rootProps={getRootProps({ className: classes.dropzone })}
        inputProps={getInputProps()}
      />

      {!!fileRejections.length && <ErrorMessage message={'Пожалуйста вставьте 1 изображение'} />}

      {image && (
        <div className={classes.imageBlock}>
          <Button type='button' onClick={() => setValue('image', '')}>
            Удалить картинку
          </Button>

          <img
            src={acceptedFiles[0] ? URL.createObjectURL(acceptedFiles[0]) : image}
            alt='Изображение'
          />
        </div>
      )}
    </>
  );
}
