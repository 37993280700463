import { createSelector } from '@reduxjs/toolkit';

const selectCartState = createSelector(
  (state) => state,
  (state) => state.cartState
);

export const selectCartItems = createSelector(selectCartState, (state) => state.cartItems);

export const selectCartItemById = (id) =>
  createSelector(selectCartItems, (items) => items.find((p) => p.id === id));
