const slides = [
  {
    id: 1,
    img: '/img/swiper/1.jpg',
    url: 'https://shark-pro.com/catalog/carLifts/rollingColumns',
  },
  {
    id: 2,
    img: '/img/swiper/2.jpg',
    url: 'https://shark-pro.com/cardProduct/661408fa82248a80f0a193e9',
  },
  {
    id: 3,
    img: '/img/swiper/3.jpg',
    url: 'https://shark-pro.com/cardProduct/661408f982248a80f0a1936f',
  },
  {
    id: 4,
    img: '/img/swiper/4.jpg',
    url: 'https://shark-pro.com/catalog/carLifts/parkingSystems',
  },
  {
    id: 5,
    img: '/img/swiper/5.jpg',
    url: '/',
  },
  {
    id: 6,
    img: '/img/swiper/6.webp',
    url: '/',
  },
  {
    id: 7,
    img: 'https://si-tools.ru/upload/iblock/c9c/wvum5g3w45vwxs94irdcsv7flhsuy92b.webp',
    url: '/',
  },
  {
    id: 8,
    img: 'https://si-tools.ru/upload/iblock/702/xox1jk1r13to126gqhxm1y6k4dn8cd21.webp',
    url: '/',
  },
];

export default slides;
