import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import styles from './Header.module.scss';
import SearchProduct from '../../../components/SearchProduct/SearchProducts';
import { useSelectCartItems, useSelectFavorites } from '../../../store';

export default function Header() {
  const [isOpen, setOpen] = useState(false);
  const burgerMenuRef = useRef(null);

  const favoritesItems = useSelectFavorites();
  const cartItems = useSelectCartItems();

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const target = e.target;

      if (!burgerMenuRef.current) {
        return;
      }

      if (!burgerMenuRef.current.contains(target)) {
        setOpen(false);
      }
    });
  }, []);

  const closeNav = () => {
    setOpen(false);
  };

  const toggleBurger = (e) => {
    e.stopPropagation();
    setOpen(!isOpen);
  };

  return (
    <header className={`${styles.header} ${isOpen ? styles.open : ''}`}>
      <div className='container'>
        <div className={styles.header__top}>
          <div className={styles.header__content}>
            <Link to={'/'} className={styles.header__logo}>
              <svg
                className={styles.header__svg}
                xmlns='http://www.w3.org/2000/svg'
                width='547.000000pt'
                height='187.000000pt'
                viewBox='0 0 547.000000 187.000000'
                preserveAspectRatio='xMidYMid meet'
                strokeWidth='30'
                strokeLinecap='square'
              >
                <g transform='translate(0.000000,187.000000) scale(0.100000,-0.100000)'>
                  <path
                    d='M2968 1828 c4 -18 7 -89 7 -158 0 -142 -20 -224 -59 -240 -11 -5
                -131 -34 -266 -65 -135 -31 -375 -88 -533 -127 l-289 -70 -43 31 c-24 17 -68
                42 -97 56 l-53 25 -5 -73 c-3 -39 -10 -81 -17 -93 -9 -15 -52 -33 -170 -68
                -87 -27 -173 -54 -191 -62 -32 -13 -33 -13 -115 53 -180 146 -414 279 -662
                376 -136 54 -340 118 -421 133 l-39 7 55 -44 c30 -24 120 -95 200 -158 261
                -206 495 -412 571 -503 l48 -58 -39 -89 c-22 -50 -46 -118 -55 -152 -18 -67
                -22 -156 -8 -169 4 -5 117 83 250 195 273 229 228 207 445 220 l96 6 12 -56
                c7 -32 10 -77 8 -101 -2 -24 -1 -44 2 -44 17 0 140 115 164 154 23 36 35 46
                55 46 14 -1 145 -9 290 -19 l265 -19 24 -28 c28 -35 99 -140 105 -157 6 -18
                110 65 144 113 l28 42 115 -7 c63 -3 221 -12 350 -20 129 -8 305 -17 391 -21
                l157 -7 35 -71 c59 -119 71 -174 72 -324 0 -149 -6 -143 83 -89 68 40 180 158
                211 221 31 64 51 140 51 201 l0 51 208 12 c420 24 761 101 902 203 40 29 41
                31 25 49 -29 32 -183 105 -320 153 -301 104 -840 215 -1406 292 -45 6 -47 7
                -59 51 -29 101 -155 241 -298 331 -65 41 -200 103 -223 103 -5 0 -5 -15 -1
                -32z'
                  />
                </g>
              </svg>
              <p className={styles.span__logo}>shark pro</p>
            </Link>

            <nav ref={burgerMenuRef} className={`${styles.nav} ${isOpen ? styles.active : ''}`}>
              <ul className={`${styles.nav__list} list-reset`}>
                <li className={styles.nav__item}>
                  <NavLink onClick={closeNav} className={`${styles.nav__link} link__anim`} to='/'>
                    Главная
                  </NavLink>
                </li>
                <li className={styles.nav__item}>
                  <NavLink
                    onClick={closeNav}
                    className={`${styles.nav__link} link__anim`}
                    to='/about'
                  >
                    О нас
                  </NavLink>
                </li>
                <li className={styles.nav__item}>
                  <NavLink
                    onClick={closeNav}
                    className={`${styles.nav__link} link__anim`}
                    to='/catalog'
                  >
                    Каталог
                  </NavLink>
                </li>
                <li className={styles.nav__item}>
                  <NavLink
                    onClick={closeNav}
                    className={`${styles.nav__link} link__anim`}
                    to='/contacts'
                  >
                    Контакты
                  </NavLink>
                </li>
              </ul>
            </nav>

            <div className={styles.basket}>
              <Link to={'/favorites'} className={styles.like}>
                <svg
                  width='30px'
                  height='30px'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M18 18.86H17.24C16.44 18.86 15.68 19.17 15.12 19.73L13.41 21.42C12.63 22.19 11.36 22.19 10.58 21.42L8.87 19.73C8.31 19.17 7.54 18.86 6.75 18.86H6C4.34 18.86 3 17.53 3 15.89V4.97998C3 3.33998 4.34 2.01001 6 2.01001H18C19.66 2.01001 21 3.33998 21 4.97998V15.89C21 17.52 19.66 18.86 18 18.86Z'
                    stroke='#9a4eae'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12.28 14.96C12.13 15.01 11.88 15.01 11.72 14.96C10.42 14.51 7.5 12.66 7.5 9.51001C7.5 8.12001 8.62 7 10 7C10.82 7 11.54 7.39 12 8C12.46 7.39 13.18 7 14 7C15.38 7 16.5 8.12001 16.5 9.51001C16.49 12.66 13.58 14.51 12.28 14.96Z'
                    stroke='#9a4eae'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <p
                  className={
                    favoritesItems.length === 0 ? styles.circleFavorit : styles.circleFavorit2
                  }
                >
                  {favoritesItems.length}
                </p>
              </Link>

              <Link to={'/cart'} className={styles.basket__cart}>
                <img
                  className={`${styles.basket__logo} logo__anim`}
                  src='/img/basket.png'
                  alt='basket'
                />
                <p className={cartItems.length === 0 ? styles.basket__span : styles.basket__span2}>
                  {cartItems.length}
                </p>
              </Link>

              <button
                ref={burgerMenuRef}
                onClick={toggleBurger}
                className={`${styles.burger} btn-reset`}
              >
                <span></span>
              </button>
            </div>
          </div>

          <SearchProduct />

          <div className={styles.header__bottom}>
            <div className={styles.header__contacts}>
              <div className={styles.header__clock}>
                <img src='/img/clock.png' alt='logo' />
                <span className={styles.contacts__span}>Режим работы: С 10:00 до 18:00 пн-пт</span>
              </div>

              <a
                className={`${styles.header__email} link-anim-2`}
                href='mailto:shark-samara@inbox.ru'
              >
                <img src='/img/email.png' alt='email logo' />
                shark-samara@inbox.ru
              </a>

              <div className={styles.header__tel__tg}>
                <a className={`${styles.header__tel} link-anim-2`} href='tel:+79277292818'>
                  <img src='/img/telrfon.png' alt='logo' />
                  8-927-729-28-18
                </a>

                <a className={`${styles.header__tg} logo__anim`} href='https://t.me/+79277292818'>
                  <img src='/img/socia/telegram.svg' alt='telegram-logo' />
                </a>

                <a
                  className={`${styles.header__whatsapp} logo__anim`}
                  href='https://wa.me/79277292818?text=Привет!'
                >
                  <img src='/img/socia/whatsapp.svg' alt='telegram-logo' />
                </a>

                <a
                  className={`${styles.header__whatsapp} logo__anim`}
                  href='viber://chat?number=%2B79277292818'
                >
                  <img src='/img/socia/viber.svg' alt='viber-logo' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
