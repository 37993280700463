import { array, mixed, number, object, string } from 'yup';

export const productSchema = object().shape({
  id: string(),
  title: string().required('Название - обязательное поле'),
  price: number().typeError('Цена - обязательное поле').required('Цена - обязательное поле'),
  stock: number()
    .typeError('В наличие - обязательное поле')
    .required('В наличие - обязательное поле'),
  image: mixed().required('Изображение - обязательное поле'),
  additionalImages: array().of(mixed()).default([]),
  description: string(),
  discount: number()
    .max(100)
    .transform((_, val) => (val === '' ? 0 : +val))
    .nullable()
    .default(0),
  parameters: array().of(
    object().shape({
      id: string().required(),
      title: string().required(),
    })
  ),
  characteristics: string().required(),
  peculiarities: string().required(),
});
