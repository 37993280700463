import { apiPath } from '../apiPath';
import { $api } from '../configuration';

export const makeLogin = (data) => {
  return $api.post(apiPath.login, data);
};

export const refreshToken = () => {
  return $api(apiPath.refreshToken, { withCredentials: true });
};
