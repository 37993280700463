import React, { useMemo, useRef } from 'react';
import { Modal } from '../Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInput } from '../Input';
import { productSchema } from '../../shared';
import classes from './ProductModal.module.scss';
import { ErrorMessage } from '../ErrorMessage';
import Multiselect from 'multiselect-react-dropdown';
import { getDefaultValues } from './helpers/getDefaultValues';
import { HeadImage } from './components/HeadImage';
import { AdditionalImages } from './components/AdditionalImages';
import { useCreateProduct, useUpdateProduct } from '../../store';
import { getValuesForSubmit } from './helpers/getValuesForSubmit';
import { Loader } from '../loader/loader';

export const ProductModal = ({ onClose, product, allParameters, subCategoryName }) => {
  const formRef = useRef();
  const [createProduct, createLoading, _, createError] = useCreateProduct();
  const [updateProduct, updateLoading, __, updateError] = useUpdateProduct();
  const defaultValues = useMemo(() => getDefaultValues(product, allParameters), [product]);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
    resolver: yupResolver(productSchema),
    mode: 'all',
  });
  const { parameters } = watch({ control, name: 'parameters' });

  const onSubmit = async (values) => {
    const formData = getValuesForSubmit(formRef.current, values, subCategoryName);

    if (product?.id) {
      updateProduct(formData, onClose);
    } else {
      createProduct(formData, onClose);
    }
  };

  return (
    <Modal
      onClose={onClose}
      disableSubmit={createLoading || updateLoading}
      submitBtnName='Сохранить'
      formId='ProductForm'
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='ProductForm'
        className={classes.form}
        ref={formRef}
      >
        {(updateError || createError) && <ErrorMessage message={updateError || createError} />}

        <ControlledInput
          name='title'
          control={control}
          label='Отображаемое название'
          labelClass={classes.label}
        />
        <ControlledInput
          type='number'
          name='price'
          control={control}
          label='Цена'
          labelClass={classes.label}
        />
        <ControlledInput
          type='number'
          name='stock'
          control={control}
          label='В наличие'
          labelClass={classes.label}
        />

        <HeadImage control={control} setValue={setValue} />

        <AdditionalImages control={control} setValue={setValue} />

        <ControlledInput
          type='textarea'
          name='description'
          control={control}
          label='Описание'
          labelClass={classes.label}
          rows={3}
        />

        <ControlledInput
          type='number'
          name='discount'
          control={control}
          label='Скидка'
          labelClass={classes.label}
        />

        <label>
          <span className={classes.params}>Параметры</span>

          <Multiselect
            options={allParameters}
            className={classes.paramsInput}
            selectedValues={parameters}
            onSelect={(selectedList) => {
              setValue('parameters', selectedList);
            }}
            onRemove={(selectedList) => {
              setValue('parameters', selectedList);
            }}
            displayValue='title'
            placeholder=''
          />
        </label>

        <ControlledInput
          type='textarea'
          name='characteristics'
          control={control}
          label='Характеристики'
          labelClass={classes.label}
          rows={3}
        />

        <ControlledInput
          type='textarea'
          name='peculiarities'
          control={control}
          label='Особенности'
          labelClass={classes.label}
          rows={3}
        />

        {(createLoading || updateLoading) && <Loader />}
      </form>
    </Modal>
  );
};
