import { createSlice } from '@reduxjs/toolkit';

import {
  createCategoryAction,
  deleteCategoryAction,
  getCategoriesAction,
  getCategoryAction,
  updateCategoryAction,
} from './actions';

const initialState = {
  categories: [],
};

const categoriesSlice = createSlice({
  initialState,
  name: 'categories',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoriesAction.fulfilled, (state, { payload }) => {
      state.categories = payload;
    });

    builder.addCase(getCategoryAction.fulfilled, (state, { payload }) => {
      state.categories.push(payload);
    });

    builder.addCase(createCategoryAction.fulfilled, (state, { payload }) => {
      state.categories.push(payload);
    });

    builder.addCase(updateCategoryAction.fulfilled, (state, { payload }) => {
      const categoryIdx = state.categories.findIndex((c) => c.id === payload.id);

      if (categoryIdx !== -1) {
        state.categories[categoryIdx] = { ...state.categories[categoryIdx], ...payload };
      }
    });

    builder.addCase(deleteCategoryAction.fulfilled, (state, { payload }) => {
      const categoryIdx = state.categories.findIndex((c) => c.id === payload.id);

      if (categoryIdx !== -1) {
        state.categories.splice(categoryIdx, 1);
      }
    });
  },
});

export const categoriesReducer = categoriesSlice.reducer;
