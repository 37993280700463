import { useRef, useState } from 'react';

import { useEvent } from './useEvent';

export const usePromiseCbWithState = (cb, options = {}) => {
  const [loading, setLoading] = useState(options?.defaultLoading || false);
  const [error, setError] = useState('');
  const [resolved, setResolved] = useState(options?.defaultResolved || false);
  const idRef = useRef(0);

  const invoke = useEvent(async (...args) => {
    const count = ++idRef.current;
    const { delay = 200, minLoading = 500 } = options;

    let minLoadingPromise = undefined;
    setTimeout(() => {
      if (count === idRef.current) {
        minLoadingPromise = new Promise((resolve) => {
          setTimeout(resolve, minLoading);
        });
        setLoading(true);
        setError('');
      }
    }, delay);

    setResolved(false);
    try {
      const result = await cb(...args);
      setResolved(true);
      return result;
    } catch (e) {
      if (options.error) {
        setError(options.error);
      } else {
        setError(e?.message);
      }
    } finally {
      if (minLoadingPromise) {
        await minLoadingPromise;
      }

      if (count === idRef.current) {
        idRef.current = -1;
        setLoading(false);
      }
    }
  });

  return [invoke, loading, resolved, error];
};
