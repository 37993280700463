import React, { forwardRef, useEffect, useState } from 'react';
import cn from './SearchInput.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

export const SearchInput = forwardRef(({ wrapperClassName, onClear, ...props }, ref) => {
  const [value, setValue] = useState(props.defaultValue || '');

  useEffect(() => {
    if (props.defaultValue !== value) {
      setValue(props.defaultValue || '');
    }
  }, [props.defaultValue]);

  const onChange = (event) => {
    setValue(event.target.value);
    props?.onChange(event.target.value);
  };

  const resetSearch = () => {
    props?.onChange('');
    onClear?.();
    setValue('');
  };

  return (
    <div className={`${cn.wrapper} ${wrapperClassName}`}>
      <input
        ref={ref}
        value={value}
        placeholder='Поиск товара...'
        {...props}
        className={`${cn.input} ${props.className}`}
        onChange={onChange}
      />

      <CloseIcon onClick={resetSearch} className={cn.clear} />
    </div>
  );
});

SearchInput.displayName = 'SearchInput';
