import React from "react";
import styles from "./About.module.scss";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <section className={styles.about}>
      <div className="container">
        <ul className="breadcrumb list-reset">
          <li>
            <Link to={"/"}>Главная</Link>
          </li>
          <li>О нас</li>
        </ul>
        <h1>О компании</h1>
        <p>
          Для современной автомастерской очень важно шагать в ногу со временем,
          регулярно расширяя арсенал своих возможностей за счет
          высокотехнологичного инструмента и оборудования. Компания «Шарк Про»
          имеет колоссальный опыт оснащения станций технического обслуживания,
          автомастерских, гаражей, автомоек, шиномонтажей и любых других
          предприятий сходного профиля.
        </p>
        <h2>Какие виды оборудования мы предлагаем</h2>
        <p>
          Поскольку современный автомобиль представляет собой довольно сложное
          устройство с технологической точки зрения, для его всестороннего
          обслуживания понадобиться внушительный набор средств:
        </p>
        <ul>
          <li>
            <b>Для шиномонтажных мастерских:</b>
            <p>
              станки для рихтовки и прокатки дисков, балансировочные стенды,
              компрессоры, домкраты, шиномонтажные станки, пневмоинструменты,
              вулканизаторы, азотные генераторы и всевозможные расходники.
            </p>
          </li>
          <li>
            <b>Для СТО:</b>
            <p>
              станки для рихтовки и прокатки дисков, балансировочные стенды,
              компрессоры, домкраты, шиномонтажные станки, пневмоинструменты,
              вулканизаторы, азотные генераторы и всевозможные расходники.
            </p>
          </li>
          <li>
            <b>Для покрасочных мастерских:</b>
            <p>
              покрасочные и покрасочно-сушильные камеры, инфракрасные сушильные
              лампы, покрасочные пистолеты, аэрографы.
            </p>
          </li>
          <li>
            <b>Для автомоек:</b>
            <p>
              моющие и специализированные пылесосы, генераторы пены, моющие
              средства, аппараты высокого давления, установки с подогревом воды
              и без подогрева.
            </p>
          </li>
        </ul>

        <p>
          Все это и многое другое в полной мере представлено на сайте нашей
          компании. У нас имеется огромный выбор любого оборудования, которое
          только может понадобиться современной автомастерской. Да и другие
          предприятия нередко приобретают у нас оборудования и инструменты:
          заводы, строительные и транспортные компании. - Одинаково успешно
          сотрудничаем мы и с частными лицами, которые приобретают инструмент
          для оснащения гаража. Не отстает от количества и качество предлагаемых
          нами товаров. Мы занимаемся продажей инструментов и оборудования
          только от проверенных производителей: Launch, Tromelberg, Peak, Atis,
          Remeza, Aircast, JTC, Karcher, Nordberg, Guliano, Astra, Jonnesway,
          Foerch, Cworks, Арсенал, Станкоимпорт, OMA. На все товары
          предоставляется гарантия и сертификат качества.{" "}
        </p>

        <h2>Как мы работаем</h2>
        <p>
          Сделать заказ вы сможете из любого населенного пункта страны, мы с
          радостью отправим инструменты подходящей вам службой доставки. А по
          Самаре доставим ваш заказ самостоятельно с минимальными затратами
          времени. Если же вы не можете определиться с выбором необходимого вам
          оборудования, вам поможет наш консультант. Мы готовы выехать к вам на
          предприятие для оценки ситуации, это даст нам возможность подобрать
          оптимальное решение для вас и вашего бизнеса. Звоните, мы поможем вам
          наладить работу мастерской и оснастить предприятие всем необходимым
          оборудованием!
        </p>
      </div>
    </section>
  );
}
