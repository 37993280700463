import { useSelector, useDispatch } from 'react-redux';

import {
  deleteCategoryAction,
  getCategoriesAction,
  createCategoryAction,
  updateCategoryAction,
  getCategoryAction,
} from './actions';
import { selectCategories, selectCategoryByName } from './selectors';
import { usePromiseCbWithState } from '../../hooks';

export function useSelectCategories() {
  return useSelector(selectCategories);
}

export function useSelectCategoryByName(name) {
  return useSelector(selectCategoryByName(name));
}

export const useGetCategories = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(() => dispatch(getCategoriesAction()).unwrap(), {
    defaultLoading: true,
    error: 'Не удалось загрузить категории, попробуйте позже',
  });
};

export const useGetCategoryByName = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState((name) => dispatch(getCategoryAction(name)));
};

export const useCreateCategory = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(createCategoryAction(payload)).unwrap();
    afterAction();
  });
};

export const useUpdateCategory = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(updateCategoryAction(payload)).unwrap();
    afterAction();
  });
};

export const useDeleteCategory = () => {
  const dispatch = useDispatch();

  return (id) => dispatch(deleteCategoryAction(id));
};
