import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../../shared';
import { ControlledInput } from '../../components/Input';
import classes from './Admin.module.scss';
import { Button } from '../../components/Button';
import { makeLogin } from '../../api';
import { useState } from 'react';
import { useSelectAuthorized, useSetAuthorized } from '../../store';
import { Navigate, useNavigate } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';

export const Admin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const isAuthorized = useSelectAuthorized();
  const setAuthorized = useSetAuthorized();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'all',
  });

  if (isAuthorized) {
    return <Navigate to='/' />;
  }

  const onSubmit = async (values) => {
    let authorized = false;

    try {
      const res = await makeLogin(values);
      localStorage.setItem('token', res.data.accessToken);
      authorized = true;
      setError('');
      navigate('/');
    } catch {
      setError('Ошибка авторизации');
    }

    setAuthorized(authorized);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <h2>Вход</h2>

        {error && <ErrorMessage message={error} />}

        <ControlledInput name='login' control={control} labelClass={classes.label} label='Логин' />
        <ControlledInput
          name='password'
          type='password'
          control={control}
          labelClass={classes.label}
          label='Пароль'
        />

        <Button>Войти</Button>
      </form>
    </div>
  );
};
