import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footer__content}>
          <div className={styles.footer__left}>
            <div className={styles.footer__logo}>
              <svg
                className={styles.footer__svg}
                xmlns='http://www.w3.org/2000/svg'
                width='547.000000pt'
                height='187.000000pt'
                viewBox='0 0 547.000000 187.000000'
                preserveAspectRatio='xMidYMid meet'
                strokeWidth='30'
                strokeLinecap='square'
              >
                <g transform='translate(0.000000,187.000000) scale(0.100000,-0.100000)'>
                  <path
                    d='M2968 1828 c4 -18 7 -89 7 -158 0 -142 -20 -224 -59 -240 -11 -5
              -131 -34 -266 -65 -135 -31 -375 -88 -533 -127 l-289 -70 -43 31 c-24 17 -68
              42 -97 56 l-53 25 -5 -73 c-3 -39 -10 -81 -17 -93 -9 -15 -52 -33 -170 -68
              -87 -27 -173 -54 -191 -62 -32 -13 -33 -13 -115 53 -180 146 -414 279 -662
              376 -136 54 -340 118 -421 133 l-39 7 55 -44 c30 -24 120 -95 200 -158 261
              -206 495 -412 571 -503 l48 -58 -39 -89 c-22 -50 -46 -118 -55 -152 -18 -67
              -22 -156 -8 -169 4 -5 117 83 250 195 273 229 228 207 445 220 l96 6 12 -56
              c7 -32 10 -77 8 -101 -2 -24 -1 -44 2 -44 17 0 140 115 164 154 23 36 35 46
              55 46 14 -1 145 -9 290 -19 l265 -19 24 -28 c28 -35 99 -140 105 -157 6 -18
              110 65 144 113 l28 42 115 -7 c63 -3 221 -12 350 -20 129 -8 305 -17 391 -21
              l157 -7 35 -71 c59 -119 71 -174 72 -324 0 -149 -6 -143 83 -89 68 40 180 158
              211 221 31 64 51 140 51 201 l0 51 208 12 c420 24 761 101 902 203 40 29 41
              31 25 49 -29 32 -183 105 -320 153 -301 104 -840 215 -1406 292 -45 6 -47 7
              -59 51 -29 101 -155 241 -298 331 -65 41 -200 103 -223 103 -5 0 -5 -15 -1
              -32z'
                  />
                </g>
              </svg>
              <span className={styles.footer__span__logo}>shark pro</span>
            </div>

            <h2 className={styles.footer__h2}>Контакты:</h2>

            <ul className={` ${styles.footer__list} list-reset`}>
              <li className={styles.footer__item}>
                <span className={styles.footer__desk}>Адрес:</span>
                <span className={styles.footer__link}>
                  г. Самара, Московское шоссе, тер. 16 км, д. 1В, строение 1, секция 12, 1 этаж. ТЦ
                  Авто Молл.
                </span>
              </li>
              <li className={styles.footer__item}>
                <span className={styles.footer__desk}>Режим работы:</span>
                <span className={styles.footer__link}>С 10:00 до 18:00 пн-пт</span>
              </li>
              <li className={styles.footer__item}>
                <span className={styles.footer__desk}>E-mail:</span>
                <a
                  className={`${styles.footer__link} ${styles.link__anim_a}`}
                  href='mailto:shark-samara@inbox.ru'
                >
                  shark-samara@inbox.ru
                </a>
              </li>
              <li className={styles.footer__item}>
                <span className={styles.footer__desk}>Телефон</span>
                <a
                  className={`${styles.footer__link} ${styles.link__anim_a}`}
                  href='tel:+79277292818'
                >
                  8-927-729-28-18
                </a>
              </li>
            </ul>

            <div className={styles.footer__social}>
              <a
                className={`${styles.footer__tg} ${styles.logo__anim}`}
                href='https://t.me/+79277292818'
                target='_blank'
              >
                <svg
                  width={40}
                  height={40}
                  version='1.1'
                  id='Capa_1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 455.731 455.731'
                >
                  <g>
                    <rect x='0' y='0' fill='#61A8DE' width='455.731' height='455.731' />
                    <path
                      fill='#FFFFFF'
                      d='M358.844,100.6L54.091,219.359c-9.871,3.847-9.273,18.012,0.888,21.012l77.441,22.868l28.901,91.706
		c3.019,9.579,15.158,12.483,22.185,5.308l40.039-40.882l78.56,57.665c9.614,7.057,23.306,1.814,25.747-9.859l52.031-248.76
		C382.431,106.232,370.443,96.08,358.844,100.6z M320.636,155.806L179.08,280.984c-1.411,1.248-2.309,2.975-2.519,4.847
		l-5.45,48.448c-0.178,1.58-2.389,1.789-2.861,0.271l-22.423-72.253c-1.027-3.308,0.312-6.892,3.255-8.717l167.163-103.676
		C320.089,147.518,324.025,152.81,320.636,155.806z'
                    />
                  </g>
                </svg>
              </a>

              <a
                className={`${styles.footer__whatsapp} ${styles.logo__anim}`}
                href='https://wa.me/79277292818?text=Привет!'
                target='_blank'
              >
                <svg
                  width={40}
                  height={40}
                  version='1.1'
                  id='Capa_1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 455.731 455.731'
                >
                  <g>
                    <rect x='0' y='0' fill='#1BD741' width='455.731' height='455.731' />
                    <g>
                      <path
                        fill='#FFFFFF'
                        d='M68.494,387.41l22.323-79.284c-14.355-24.387-21.913-52.134-21.913-80.638
			c0-87.765,71.402-159.167,159.167-159.167s159.166,71.402,159.166,159.167c0,87.765-71.401,159.167-159.166,159.167
			c-27.347,0-54.125-7-77.814-20.292L68.494,387.41z M154.437,337.406l4.872,2.975c20.654,12.609,44.432,19.274,68.762,19.274
			c72.877,0,132.166-59.29,132.166-132.167S300.948,95.321,228.071,95.321S95.904,154.611,95.904,227.488
			c0,25.393,7.217,50.052,20.869,71.311l3.281,5.109l-12.855,45.658L154.437,337.406z'
                      />
                      <path
                        fill='#FFFFFF'
                        d='M183.359,153.407l-10.328-0.563c-3.244-0.177-6.426,0.907-8.878,3.037
			c-5.007,4.348-13.013,12.754-15.472,23.708c-3.667,16.333,2,36.333,16.667,56.333c14.667,20,42,52,90.333,65.667
			c15.575,4.404,27.827,1.435,37.28-4.612c7.487-4.789,12.648-12.476,14.508-21.166l1.649-7.702c0.524-2.448-0.719-4.932-2.993-5.98
			l-34.905-16.089c-2.266-1.044-4.953-0.384-6.477,1.591l-13.703,17.764c-1.035,1.342-2.807,1.874-4.407,1.312
			c-9.384-3.298-40.818-16.463-58.066-49.687c-0.748-1.441-0.562-3.19,0.499-4.419l13.096-15.15
			c1.338-1.547,1.676-3.722,0.872-5.602l-15.046-35.201C187.187,154.774,185.392,153.518,183.359,153.407z'
                      />
                    </g>
                  </g>
                </svg>
              </a>

              <a
                className={`${styles.footer__viber} ${styles.logo__anim}`}
                href='viber://chat?number=%2B79277292818'
                target='_blank'
              >
                <svg
                  height={40}
                  width={40}
                  version='1.1'
                  id='Capa_1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 455.731 455.731'
                >
                  <g>
                    <rect x='0' y='0' fill='#7D3DAF' width='455.731' height='455.731' />
                    <g>
                      <path
                        fill='#FFFFFF'
                        d='M371.996,146.901l-0.09-0.36c-7.28-29.43-40.1-61.01-70.24-67.58l-0.34-0.07
			c-48.75-9.3-98.18-9.3-146.92,0l-0.35,0.07c-30.13,6.57-62.95,38.15-70.24,67.58l-0.08,0.36c-9,41.1-9,82.78,0,123.88l0.08,0.36
			c6.979,28.174,37.355,58.303,66.37,66.589v32.852c0,11.89,14.49,17.73,22.73,9.15l33.285-34.599
			c7.219,0.404,14.442,0.629,21.665,0.629c24.54,0,49.09-2.32,73.46-6.97l0.34-0.07c30.14-6.57,62.96-38.15,70.24-67.58l0.09-0.36
			C380.996,229.681,380.996,188.001,371.996,146.901z M345.656,264.821c-4.86,19.2-29.78,43.07-49.58,47.48
			c-25.921,4.929-52.047,7.036-78.147,6.313c-0.519-0.014-1.018,0.187-1.38,0.559c-3.704,3.802-24.303,24.948-24.303,24.948
			l-25.85,26.53c-1.89,1.97-5.21,0.63-5.21-2.09v-54.422c0-0.899-0.642-1.663-1.525-1.836c-0.005-0.001-0.01-0.002-0.015-0.003
			c-19.8-4.41-44.71-28.28-49.58-47.48c-8.1-37.15-8.1-74.81,0-111.96c4.87-19.2,29.78-43.07,49.58-47.48
			c45.27-8.61,91.17-8.61,136.43,0c19.81,4.41,44.72,28.28,49.58,47.48C353.765,190.011,353.765,227.671,345.656,264.821z'
                      />
                      <path
                        fill='#FFFFFF'
                        d='M270.937,289.942c-3.044-0.924-5.945-1.545-8.639-2.663
			c-27.916-11.582-53.608-26.524-73.959-49.429c-11.573-13.025-20.631-27.73-28.288-43.292c-3.631-7.38-6.691-15.049-9.81-22.668
			c-2.844-6.948,1.345-14.126,5.756-19.361c4.139-4.913,9.465-8.673,15.233-11.444c4.502-2.163,8.943-0.916,12.231,2.9
			c7.108,8.25,13.637,16.922,18.924,26.485c3.251,5.882,2.359,13.072-3.533,17.075c-1.432,0.973-2.737,2.115-4.071,3.214
			c-1.17,0.963-2.271,1.936-3.073,3.24c-1.466,2.386-1.536,5.2-0.592,7.794c7.266,19.968,19.513,35.495,39.611,43.858
			c3.216,1.338,6.446,2.896,10.151,2.464c6.205-0.725,8.214-7.531,12.562-11.087c4.25-3.475,9.681-3.521,14.259-0.624
			c4.579,2.898,9.018,6.009,13.43,9.153c4.331,3.086,8.643,6.105,12.638,9.623c3.841,3.383,5.164,7.821,3.001,12.412
			c-3.96,8.408-9.722,15.403-18.034,19.868C276.387,288.719,273.584,289.127,270.937,289.942
			C267.893,289.017,273.584,289.127,270.937,289.942z'
                      />
                      <path
                        fill='#FFFFFF'
                        d='M227.942,131.471c36.515,1.023,66.506,25.256,72.933,61.356c1.095,6.151,1.485,12.44,1.972,18.683
			c0.205,2.626-1.282,5.121-4.116,5.155c-2.927,0.035-4.244-2.414-4.434-5.039c-0.376-5.196-0.637-10.415-1.353-15.568
			c-3.78-27.201-25.47-49.705-52.545-54.534c-4.074-0.727-8.244-0.918-12.371-1.351c-2.609-0.274-6.026-0.432-6.604-3.675
			c-0.485-2.719,1.81-4.884,4.399-5.023C226.527,131.436,227.235,131.468,227.942,131.471
			C264.457,132.494,227.235,131.468,227.942,131.471z'
                      />
                      <path
                        fill='#FFFFFF'
                        d='M283.434,203.407c-0.06,0.456-0.092,1.528-0.359,2.538c-0.969,3.666-6.527,4.125-7.807,0.425
			c-0.379-1.098-0.436-2.347-0.438-3.529c-0.013-7.734-1.694-15.46-5.594-22.189c-4.009-6.916-10.134-12.73-17.318-16.248
			c-4.344-2.127-9.042-3.449-13.803-4.237c-2.081-0.344-4.184-0.553-6.275-0.844c-2.534-0.352-3.887-1.967-3.767-4.464
			c0.112-2.34,1.822-4.023,4.372-3.879c8.38,0.476,16.474,2.287,23.924,6.232c15.15,8.023,23.804,20.687,26.33,37.597
			c0.114,0.766,0.298,1.525,0.356,2.294C283.198,199.002,283.288,200.903,283.434,203.407
			C283.374,203.863,283.288,200.903,283.434,203.407z'
                      />
                      <path
                        fill='#FFFFFF'
                        d='M260.722,202.523c-3.055,0.055-4.69-1.636-5.005-4.437c-0.219-1.953-0.392-3.932-0.858-5.832
			c-0.918-3.742-2.907-7.21-6.055-9.503c-1.486-1.083-3.17-1.872-4.934-2.381c-2.241-0.647-4.568-0.469-6.804-1.017
			c-2.428-0.595-3.771-2.561-3.389-4.839c0.347-2.073,2.364-3.691,4.629-3.527c14.157,1.022,24.275,8.341,25.719,25.007
			c0.102,1.176,0.222,2.419-0.039,3.544C263.539,201.464,262.113,202.429,260.722,202.523
			C257.667,202.578,262.113,202.429,260.722,202.523z'
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>

          <div className={styles.footer__center}>
            <YMaps>
              <Map
                width={300}
                height={320}
                defaultState={{ center: [53.262779, 50.2444], zoom: 16 }}
              >
                <Placemark geometry={[53.262779, 50.2444]} />
              </Map>
            </YMaps>
          </div>

          <div className={styles.footer__right}>
            <h2 className={styles.fotter__nav__h2}>Навигация</h2>

            <ul className={` ${styles.footer__nav} list-reset`}>
              <li className={styles.footer__nav__item}>
                <Link className={`${styles.footer__nav__link} ${styles.link__anim_a}`} to='/'>
                  Главная
                </Link>
              </li>
              <li className={styles.footer__nav__item}>
                <Link className={`${styles.footer__nav__link} ${styles.link__anim_a}`} to='/about'>
                  О нас
                </Link>
              </li>
              <li className={styles.footer__nav__item}>
                <Link
                  className={`${styles.footer__nav__link} ${styles.link__anim_a}`}
                  to='/catalog'
                >
                  Каталог
                </Link>
              </li>
              <li className={styles.footer__nav__item}>
                <Link
                  className={`${styles.footer__nav__link} ${styles.link__anim_a}`}
                  to='/contacts'
                >
                  Контакты
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <p className={styles.footerDescription}>
          Сайт не является публичной офертой, определяемой положениями Статьи 437 (2) ГК РФ и носит
          исключительно информационный характер. Для получения точной информации о наличии и
          стоимости товара, пожалуйста, обращайтесь по указанным контактным данным.
        </p>

        <div className={styles.developer}>
          <span className={styles.companyName}>*SHARK PRO | ШАРК ПРО</span>
          <img src='/img/noga-cricle.webp' alt='logo' />
          <a href='/'>NOGA DEV 2024</a>
        </div>
      </div>
    </footer>
  );
}
