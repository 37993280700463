import React from 'react';
import styles from './CardProduct.module.scss';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams } from 'react-router-dom';
import LightGallery from 'lightgallery/react/Lightgallery.es5';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import {
  useSelectCartItemById,
  useSelectIsFavorite,
  useToggleCartItem,
  useToggleFavorite,
} from '../../store';
import { formatPrice, getPriceWithDiscount } from '../../shared';
import { useGetProduct } from './useGetProduct';
import { Skeleton } from '../../components/Skeleton';

export default function CardProduct() {
  const { id } = useParams();

  const isFavorite = useSelectIsFavorite(id);
  const inCart = useSelectCartItemById(id);

  const toggleFavorite = useToggleFavorite();
  const toggleCartItem = useToggleCartItem();

  const { product, loading } = useGetProduct(id);

  if (loading) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton className={styles.skeleton} />
      </div>
    );
  }

  if (!loading && !product) {
    return (
      <section className={styles.cardProduct}>
        <div className={styles.skeletonWrapper}>
          <Link to={'/catalog'} className={styles.no__product}>
            <h4>Не удалось загрузить товар.</h4>
            <p>Возможно данный товар был удален.</p>
            <p>Нажми на меня чтобы вернуться к выбору товара</p>
          </Link>
        </div>
      </section>
    );
  }

  return (
    <section className={styles.cardProduct}>
      <div className='container'>
        <ul className='breadcrumb list-reset'>
          <li>
            <Link to={'/'}>Главная</Link>
          </li>
          <li>{product.title}</li>
        </ul>

        <div className={styles.card}>
          <div className={styles.card__img}>
            <LightGallery
              elementClassNames={styles.angry__grid}
              speed={500}
              plugins={[lgZoom, lgThumbnail]}
            >
              <a href={product.image}>
                <img
                  src={product.image === '' ? '/img/no_photo.webp' : product.image}
                  alt={product.title}
                />
              </a>
              {product?.additionalImages?.length === 0 && (
                <a href='/img/no_photo.webp'>
                  <img src='/img/no_photo.webp' alt='logo' />
                </a>
              )}
              {product?.additionalImages?.map((img, index) => (
                <a key={index} href={img}>
                  <img src={img} alt='logo' />
                </a>
              ))}
            </LightGallery>
            {product?.discount === 0 || (
              <span className={styles.discount}>Скидка: - {product.discount} %</span>
            )}
          </div>

          <div className={styles.characteristics}>
            <h2>{product.title}</h2>
            <ul className='list-reset'>
              {product?.characteristics?.map((characteristics, index) => (
                <li className={styles.characteristics__item} key={index}>
                  {characteristics}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.card__control}>
          <span className={styles.stock}>{product?.stock === 0 ? 'В пути' : `В наличии`}</span>

          <span style={{ color: product?.discount === 0 || '#daa520' }} className={styles.price}>
            <b>Цена:</b> &ensp;
            {formatPrice(getPriceWithDiscount(product.price, product?.discount))} ₽ &#8194;
            {product?.discount === 0 || (
              <b className={styles.price__no}>{formatPrice(product.price)} ₽</b>
            )}
          </span>

          <div className={styles.btn__groop}>
            <button
              onClick={() => toggleFavorite(product)}
              className={`${
                isFavorite ? styles.btnAddFavorit__on : styles.btnAddFavorit
              } btn-reset`}
            >
              <svg
                width='30px'
                height='30px'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z'
                  fill='#1C274C'
                />
              </svg>
            </button>
            <button
              onClick={() => toggleCartItem({ ...product, count: 1 })}
              className={`${inCart ? styles.btnAddCart__On : styles.btnAddCart} btn-reset`}
            >
              {inCart ? 'Добавленно' : 'Добавить в корзину'}
            </button>
          </div>
        </div>

        <div className={styles.card__info}>
          <Tabs className={`${styles.react__tabs} react__tabs`}>
            <TabList className={`${styles.tab__list} list-reset tab__list `}>
              <Tab>
                <b>Описание</b>
              </Tab>
              <Tab>
                <b>Особенности</b>
              </Tab>
            </TabList>

            <TabPanel className={styles.tab__panel}>
              <div className={styles.tab__content}>
                <p className={styles.description}>{product?.description?.trim()}</p>
              </div>
            </TabPanel>
            <TabPanel className={styles.tab__panel}>
              <div className={styles.tab__content}>
                <ul>
                  {product?.peculiarities?.map((peculiarities, index) => (
                    <li key={index}>{peculiarities}</li>
                  ))}
                </ul>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </section>
  );
}
