import React, { useRef } from 'react';
import { Modal } from '../Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInput } from '../Input';
import { categorySchema } from '../../shared';
import { ErrorMessage } from '../ErrorMessage';
import { useCreateCategory, useUpdateCategory } from '../../store';
import { Button } from '../Button';
import classes from './CategoryModal.module.scss';
import { useDropzone } from 'react-dropzone';
import { ControlledDropzone } from '../Input/ControlledDropzone';
import { Loader } from '../loader/loader';

export const CategoryModal = ({ onClose, defaultValues, imageUrl }) => {
  const formRef = useRef();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (files) => {
      setValue('image', files[0], {
        shouldValidate: true,
      });
    },
  });

  const [createCategory, createLoading, _, createError] = useCreateCategory();
  const [updateCategory, updateLoading, __, updateError] = useUpdateCategory();

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
    resolver: yupResolver(categorySchema),
    mode: 'all',
  });

  const { image } = watch({ control, name: 'image' });

  const onSubmit = async () => {
    const formData = new FormData(formRef.current);

    if (acceptedFiles[0]) {
      formData.append('image', acceptedFiles[0]);
    }

    if (defaultValues?.id) {
      formData.append('id', defaultValues.id);
      updateCategory(formData, onClose);
    } else {
      createCategory(formData, onClose);
    }
  };

  const deleteImage = () => {
    setValue('image', '');
  };

  return (
    <Modal
      onClose={onClose}
      submitBtnName='Сохранить'
      formId='categoryForm'
      disableSubmit={createLoading || updateLoading}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
        id='categoryForm'
        className={classes.form}
      >
        {(createError || updateError) && <ErrorMessage message={createError || updateError} />}
        {!!fileRejections.length && <ErrorMessage message={'Пожалуйста вставьте 1 изображение'} />}

        <ControlledInput
          name='title'
          control={control}
          label='Отображаемое название'
          labelClass={classes.label}
        />
        <ControlledInput
          name='name'
          control={control}
          label='Уникальное название категории на английском'
          labelClass={classes.label}
        />

        <ControlledDropzone
          name='image'
          control={control}
          rootProps={getRootProps({ className: classes.dropzone })}
          inputProps={getInputProps()}
        />

        {image && (
          <div className={classes.imageBlock}>
            <Button type='button' onClick={deleteImage}>
              Удалить картинку
            </Button>

            <img
              src={acceptedFiles[0] ? URL.createObjectURL(acceptedFiles[0]) : imageUrl}
              alt='Изображение'
            />
          </div>
        )}

        {(createLoading || updateLoading) && <Loader />}
      </form>
    </Modal>
  );
};
