import React, { useState, useRef } from 'react';
import { Portal } from '../Portal/Portal';
import { usePopper } from 'react-popper';
import cn from './Popover.module.scss';
import { useOutsideClick } from '../../hooks/use-on-click-outside';
import { useCombinedRef } from '../../hooks/use-combined-ref';

export default function Popover({ reference, placement, children, onClose }) {
  const [popper, setPopper] = useState(null);
  const rootRef = useRef(null);
  const { styles, attributes } = usePopper(reference, popper, { placement });
  const refs = useCombinedRef(setPopper, rootRef);
  useOutsideClick(rootRef, onClose);

  return (
    <Portal>
      <div className={cn.popover} ref={refs} style={styles.popper} {...attributes.popper}>
        {children}
      </div>
    </Portal>
  );
}
