import React, { useRef } from 'react';
import { Modal } from '../Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInput } from '../Input';
import classes from './XmlImportModal.module.scss';
import { ErrorMessage } from '../ErrorMessage';
import { xmlImportSchema } from '../../shared';
import { useUploadXml } from '../../store';
import { Loader } from '../loader/loader';

export const XmlImportModal = ({ onClose, subCategory }) => {
  const [uploadXml, loading, _, error] = useUploadXml();
  const formRef = useRef();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      id: 'id',
      title: 'name',
      price: 'price',
      image: 'picture',
      description: 'description',
      subCategoryName: 'categoryId',
    },
    resolver: yupResolver(xmlImportSchema),
    mode: 'all',
  });

  const onSubmit = async (values) => {
    uploadXml({ ...values, subCategory });
  };

  return (
    <Modal
      onClose={onClose}
      disableSubmit={loading}
      submitBtnName='Сохранить'
      formId='XmlImportForm'
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='XmlImportForm'
        className={classes.form}
        ref={formRef}
      >
        {error && <ErrorMessage message={error} />}

        <ControlledInput
          name='id'
          control={control}
          label='Название поля "id товара"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='title'
          control={control}
          label='Название поля "названия"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='price'
          control={control}
          label='Название поля "цена"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='stock'
          control={control}
          label='Название поля "в наличие"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='image'
          control={control}
          label='Название поля "картинка"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='additionalImages'
          control={control}
          label='Название поля "дополнительные картинки"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='description'
          control={control}
          label='Название поля "описание"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='discount'
          control={control}
          label='Название поля "скидка"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='characteristics'
          control={control}
          label='Название поля "характеристики"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='peculiarities'
          control={control}
          label='Название поля "особенности"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='subCategoryName'
          control={control}
          label='Название поля "id Подкатегории"'
          labelClass={classes.label}
        />
        <ControlledInput
          name='subCategoryId'
          control={control}
          label='id Подкатегории'
          labelClass={classes.label}
        />
        <ControlledInput
          name='link'
          control={control}
          label='Xml ссылка'
          labelClass={classes.label}
        />

        {loading && <Loader />}
      </form>
    </Modal>
  );
};
