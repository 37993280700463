import { apiPath } from '../apiPath';
import { $api } from '../configuration';

export const createCategory = (data) => {
  return $api.post(apiPath.categories, data, {
    headers: 'multipart/form-data',
  });
};

export const updateCategory = (data) => {
  return $api.put(apiPath.categories, data, {
    headers: 'multipart/form-data',
  });
};

export const deleteCategory = (id) => {
  return $api.delete(`${apiPath.categories}/${id}`);
};

export const getCategories = () => {
  return $api(apiPath.categories);
};

export const getCategoryByName = (name) => {
  return $api(`${apiPath.categories}/${name}`);
};
