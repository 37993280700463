import React, { useEffect } from 'react';
import { CategoryCard } from '../CategoryCard/CategoryCard';
import styles from './Catalog.module.scss';
import {
  useDeleteCategory,
  useGetCategories,
  useSelectAuthorized,
  useSelectCategories,
} from '../../store';
import { Button } from '../Button';
import { CategoryModal } from '../CategoryModal';
import { ModalService } from '../../shared';
import { Skeleton } from '../Skeleton';

const placeholders = new Array(8).fill(0);

export default function Catalog() {
  const [getCategories, loading, _, error] = useGetCategories();
  const deleteCategory = useDeleteCategory();

  const categories = useSelectCategories();
  const isAuthorized = useSelectAuthorized();

  useEffect(() => {
    getCategories();
  }, []);

  const showSubCategoryModal = () => {
    ModalService.open(CategoryModal, {});
  };

  if (error) {
    return (
      <section className={styles.catalog}>
        <h4 className={styles.errorText}>{error}</h4>
      </section>
    );
  }

  const showPlaceholders = !categories.length && loading;
  return (
    <section className={styles.catalog}>
      <div className='container'>
        <h2 className={styles.h2}>Каталог оборудования</h2>

        {isAuthorized && (
          <div className={styles.addCatalogBlock}>
            <Button onClick={showSubCategoryModal}>Добавить категорию</Button>
          </div>
        )}

        <ul className={`${styles.catalog__list} list-reset`}>
          {showPlaceholders &&
            placeholders.map((_, idx) => <Skeleton key={idx} className={styles.placeholder} />)}

          {!showPlaceholders &&
            categories.map((category) => (
              <CategoryCard
                key={category.id}
                id={category.id}
                name={category.name}
                title={category.title}
                image={category.image}
                isAuthorized={isAuthorized}
                onDelete={deleteCategory}
              />
            ))}
        </ul>
      </div>
    </section>
  );
}
