import React from 'react';
import styles from './Product.module.scss';
import BtnLike from '../BtnLike/BtnLike';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { ProductModal } from '../ProductModal';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ModalService, formatPrice, getPriceWithDiscount } from '../../shared';
import { DeleteModal } from '../DeleteModal';

export function Product({
  isFavorite,
  product,
  toggleFavorite,
  isCart,
  onAddCart,
  isAuthorized,
  parameters,
  subCategoryName,
  onDelete,
}) {
  const onAddCartClick = () => {
    onAddCart({
      id: product.id,
      title: product.title,
      price: product.price,
      image: product.image,
      count: 1,
      stock: product.stock,
      discount: product.discount,
    });
  };

  const showEditProductModal = () => {
    ModalService.open(ProductModal, {
      product,
      allParameters: parameters,
      subCategoryName,
    });
  };

  const showDeleteProductModal = () => {
    ModalService.open(DeleteModal, {
      name: product.title,
      onSubmit: () => onDelete({ id: product.id, subCategoryName }),
    });
  };

  return (
    <li key={product.id} className={`${styles.products__item} card-anim`}>
      {isAuthorized && (
        <div className={styles.controls}>
          <Button className={styles.button} onClick={showEditProductModal}>
            <EditIcon className={styles.icon} />
          </Button>

          <Button className={styles.button} onClick={showDeleteProductModal}>
            <DeleteIcon className={styles.icon} />
          </Button>
        </div>
      )}

      <Link className={styles.products__link} to={`/cardProduct/${product.id}`}>
        <div className={styles.products__img}>
          <img src={product.image === '' ? '/img/no_photo.webp' : product.image} alt='card' />
        </div>
        <div className={styles.products__info}>
          <span className={styles.products__title}>{product.title}</span>
          <div className={styles.products__price}>
            <span
              style={{ color: product.discount === 0 || '#daa520' }}
              className={styles.products__price__money}
            >
              {formatPrice(getPriceWithDiscount(product.price, product.discount))} ₽
              {product.discount === 0 || (
                <span className={styles.price__crossOut}>&#160;{product.price} ₽</span>
              )}
            </span>
          </div>
        </div>
      </Link>
      <div className={styles.products__btn}>
        <button
          onClick={onAddCartClick}
          className={`btn-reset ${isCart ? styles.btn__products__add : styles.btn__products}`}
        >
          {isCart ? 'Добавленно' : 'Добавить в корзину'}
        </button>
      </div>
      <BtnLike isFavorite={isFavorite} product={product} onClikHeart={toggleFavorite} />
      <span className={styles.stock}>{product?.stock > 0 ? `В наличии` : 'В пути'}</span>
      {product.discount === 0 || <span className={styles.discount}>- {product.discount} %</span>}
    </li>
  );
}
