import React from 'react';

import { Controller } from 'react-hook-form';

import { Input } from './Input';
import { isDefined } from '../../helpers';

export const ControlledInput = ({ control, name, ...props }) => {
  const handleBlur = (e, onBlur, onChange) => {
    let value = e.target.value;

    onChange(value);
    onBlur();
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Input
          {...field}
          {...props}
          onBlur={(e) => handleBlur(e, field.onBlur, field.onChange)}
          value={isDefined(field.value) ? field.value : ''}
          isHaveError={!!error}
          errorMessage={error?.message}
        />
      )}
    />
  );
};
