import { createSlice } from '@reduxjs/toolkit';

import {
  createParameterAction,
  createProductAction,
  createSubCategoryAction,
  deleteParameterAction,
  deleteProductAction,
  deleteSubCategoryAction,
  getSubCategoriesAction,
  getSubCategoryAction,
  updateParameterAction,
  updateProductAction,
  updateSubCategoryAction,
  uploadXmlAction,
} from './actions';
import { toDictionary } from '../../shared';

const initialState = {
  subCategories: [],
};

const subCategoriesSlice = createSlice({
  initialState,
  name: 'subCategories',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubCategoriesAction.fulfilled, (state, { payload }) => {
      state.subCategories = payload;
    });

    builder.addCase(getSubCategoryAction.fulfilled, (state, { payload }) => {
      state.subCategories = [payload];
    });

    builder.addCase(createSubCategoryAction.fulfilled, (state, { payload }) => {
      state.subCategories.push(payload);
    });

    builder.addCase(updateSubCategoryAction.fulfilled, (state, { payload }) => {
      const subCategoryIdx = state.subCategories.findIndex((c) => c.id === payload.id);

      if (subCategoryIdx !== -1) {
        state.subCategories[subCategoryIdx] = {
          ...state.subCategories[subCategoryIdx],
          ...payload,
        };
      }
    });

    builder.addCase(deleteSubCategoryAction.fulfilled, (state, { payload }) => {
      const subCategoryIdx = state.subCategories.findIndex((c) => c.id === payload.id);

      if (subCategoryIdx !== -1) {
        state.subCategories.splice(subCategoryIdx, 1);
      }
    });

    builder.addCase(createParameterAction.fulfilled, (state, { payload }) => {
      const { subCategoryName, ...parameter } = payload;
      const subCategory = state.subCategories.find((c) => c.name === payload.subCategoryName);

      if (subCategory) {
        subCategory.parameters.push(parameter);
      }
    });

    builder.addCase(updateParameterAction.fulfilled, (state, { payload }) => {
      const { subCategoryName, ...parameter } = payload;
      const subCategory = state.subCategories.find((c) => c.name === payload.subCategoryName);

      if (subCategory) {
        const param = subCategory.parameters.find((p) => p.id === parameter.id);

        if (param) {
          param.title = parameter.title;
        }
      }
    });

    builder.addCase(deleteParameterAction.fulfilled, (state, { payload }) => {
      const { subCategoryName, ...parameter } = payload;
      const subCategory = state.subCategories.find((c) => c.name === payload.subCategoryName);

      if (subCategory) {
        const paramIdx = subCategory.parameters.findIndex((p) => p.id === parameter.id);

        if (paramIdx !== -1) {
          subCategory.parameters.splice(paramIdx, 1);
        }
      }
    });

    builder.addCase(createProductAction.fulfilled, (state, { payload }) => {
      const { subCategoryName, ...product } = payload;
      const subCategory = state.subCategories.find((c) => c.name === payload.subCategoryName);

      if (subCategory) {
        subCategory.products.push(product);
      }
    });

    builder.addCase(updateProductAction.fulfilled, (state, { payload }) => {
      const { subCategoryName, ...product } = payload;
      const subCategory = state.subCategories.find((c) => c.name === payload.subCategoryName);

      if (subCategory) {
        const productIdx = subCategory.products.findIndex((p) => p.id === product.id);

        if (productIdx !== -1) {
          const allParamsById = toDictionary(subCategory.parameters, (p) => p.id);

          console.log(
            {
              ...subCategory.products[productIdx],
              ...product,
              ...(!!product.parameters && {
                parameters: product.parameters.map((paramId) => allParamsById[paramId]),
              }),
            },
            'new Data',
            product.parameters,
            'product.parameters'
          );
          subCategory.products[productIdx] = {
            ...subCategory.products[productIdx],
            ...product,
            ...(!!product.parameters && {
              parameters: product.parameters.map((paramId) => allParamsById[paramId]),
            }),
          };
        }
      }
    });

    builder.addCase(deleteProductAction.fulfilled, (state, { payload }) => {
      const { subCategoryName, ...product } = payload;
      const subCategory = state.subCategories.find((c) => c.name === subCategoryName);

      if (subCategory) {
        const productIdx = subCategory.products.findIndex((p) => p.id === product.id);

        if (productIdx !== -1) {
          subCategory.products.splice(productIdx, 1);
        }
      }
    });

    builder.addCase(uploadXmlAction.fulfilled, (state, { payload }) => {
      const subCategoryIdx = state.subCategories.findIndex(
        (category) => category.id === payload.id
      );

      if (subCategoryIdx !== -1) {
        state.subCategories[subCategoryIdx] = payload;
      }
    });
  },
});

export const subCategoriesReducer = subCategoriesSlice.reducer;
