import React from 'react';
import styles from './Cart.module.scss';
import { Link } from 'react-router-dom';
import CountProducts from '../../components/CountProducts/CountProducts';
import { formatPrice, getPriceWithDiscount } from '../../shared';
import {
  useResetCart,
  useSelectCartItems,
  useToggleCartItem,
  useUpdateCartItem,
} from '../../store';

export default function Cart() {
  const cartItems = useSelectCartItems();

  const toggleCartItem = useToggleCartItem();
  const updateCartItem = useUpdateCartItem();
  const resetCart = useResetCart();

  const calculatePrice = (orders) => {
    if (orders === undefined) {
      return 0;
    } else if (orders.length === 0) {
      return 0;
    } else {
      return orders.reduce((acc, curr) => {
        const totalPrice = curr.count * curr.price;
        return acc + Math.floor(totalPrice * (1 - curr.discount / 100));
      }, 0);
    }
  };

  return (
    <section className={styles.cart}>
      <div className='container'>
        <ul className='breadcrumb list-reset'>
          <li>
            <Link to={'/'}>Главная</Link>
          </li>
          <li>Корзина</li>
        </ul>

        <div className='cart__top'>
          <h2 className={styles.h2}>Корзина</h2>
          <div className={styles.cart__content}>
            <ul className={`${styles.cart__list} list-reset`}>
              {cartItems.length === 0 && (
                <Link to={'/catalog'} className={styles.cart__no}>
                  <div>
                    <p>Вы не добавили товары в корзину &#128064;</p>
                  </div>
                  <div>
                    <p>Нажми на меня чтобы вернуться к выбору товара</p>
                  </div>
                </Link>
              )}
              {cartItems.map((product) => {
                return (
                  <li key={product.id} className={styles.cart__item}>
                    <img
                      className={styles.cart__img}
                      width={120}
                      height={120}
                      src={product.image || '/img/no_photo.webp'}
                      alt={product.title}
                    />
                    <p className={styles.cart__title}>{product.title}</p>
                    <span
                      style={{ color: product.discount === 0 || '#daa520' }}
                      className={styles.cart__price}
                    >
                      {formatPrice(getPriceWithDiscount(product.price, product.discount))} ₽{' '}
                      {product.discount === 0 || (
                        <span className={styles.price__crossOut}>
                          {formatPrice(product.price)} ₽
                        </span>
                      )}
                    </span>
                    <div className={styles.count}>
                      <CountProducts updateCartItem={updateCartItem} product={product} />
                    </div>

                    <span
                      style={{ color: product.discount === 0 || '#daa520' }}
                      className={styles.cart__item__all}
                    >
                      Итого:{' '}
                      {formatPrice(
                        getPriceWithDiscount(product.count * product.price, product.discount)
                      )}{' '}
                      ₽
                    </span>
                    <button onClick={() => toggleCartItem(product)} className='btn-reset'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M7.10002 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4.06055L4.88474 20.1871C4.98356 21.7682 6.29471 23 7.8789 23H16.1211C17.7053 23 19.0164 21.7682 19.1153 20.1871L19.9395 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19.0073C19.0018 4.99995 18.9963 4.99995 18.9908 5H16.9C16.4367 2.71776 14.419 1 12 1C9.58104 1 7.56329 2.71776 7.10002 5ZM9.17071 5H14.8293C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5ZM17.9355 7H6.06445L6.88085 20.0624C6.91379 20.5894 7.35084 21 7.8789 21H16.1211C16.6492 21 17.0862 20.5894 17.1192 20.0624L17.9355 7ZM14.279 10.0097C14.83 10.0483 15.2454 10.5261 15.2068 11.0771L14.7883 17.0624C14.7498 17.6134 14.2719 18.0288 13.721 17.9903C13.17 17.9517 12.7546 17.4739 12.7932 16.9229L13.2117 10.9376C13.2502 10.3866 13.7281 9.97122 14.279 10.0097ZM9.721 10.0098C10.2719 9.97125 10.7498 10.3866 10.7883 10.9376L11.2069 16.923C11.2454 17.4739 10.83 17.9518 10.2791 17.9903C9.72811 18.0288 9.25026 17.6134 9.21173 17.0625L8.79319 11.0771C8.75467 10.5262 9.17006 10.0483 9.721 10.0098Z'
                          fill='#0F1729'
                        />
                      </svg>
                    </button>
                    <span className={styles.stock}>
                      {product.stock === 0 ? 'В пути' : `В наличии`}
                    </span>
                    {product.discount === 0 || (
                      <span className={styles.discount}>Скидка: - {product.discount} %</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {cartItems.length === 0 || (
          <div className={styles.cart__bottom}>
            <button onClick={resetCart} className={`${styles.cart__order__btn__clear} btn-reset`}>
              Очистить заказ
            </button>
            <span className={styles.cart__all__sum}>
              Сумма заказа: {formatPrice(calculatePrice(cartItems))} ₽
            </span>
            <Link to={'/checkout'} className={`${styles.cart__order__btn} btn-reset`}>
              Оформить заказ
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}
