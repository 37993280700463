import { apiPath } from '../apiPath';
import { $api } from '../configuration';

export const createSubCategoryParameter = (data) => {
  return $api.post(apiPath.subCategoriesParameter, data);
};

export const updateSubCategoryParameter = (data) => {
  return $api.put(apiPath.subCategoriesParameter, data);
};

export const deleteSubCategoryParameter = (id) => {
  return $api.delete(`${apiPath.subCategoriesParameter}/${id}`);
};

export const getSubCategoryParameters = (subCategoryName) => {
  return $api.get(`${apiPath.subCategoriesParameter}/${subCategoryName}`);
};

export const getSubCategoryParameter = (id) => {
  return $api(`${apiPath.subCategoriesParameter}/${id}`);
};
