import React from 'react';
import styles from './Subcategories.module.scss';
import { Link } from 'react-router-dom';
import { ModalService } from '../../shared';
import { DeleteModal } from '../../components/DeleteModal';
import { Button } from '../../components/Button';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { SubCategoryModal } from '../../components/SubCategoryModal';

export const SubCategory = ({ name, title, image, id, isAuthorized, onDelete }) => {
  const showEditCategoryModal = () => {
    ModalService.open(SubCategoryModal, {
      defaultValues: {
        id,
        name,
        title,
        image: 'has image',
      },
      imageUrl: image,
    });
  };

  const showDeleteCategoryModal = () => {
    ModalService.open(DeleteModal, {
      name: title,
      text: 'Все товары в подкатегории будут удалены',
      onSubmit: () => onDelete(id),
    });
  };

  return (
    <li className={`${styles.subCategory} card-anim`}>
      {isAuthorized && (
        <div className={styles.controls}>
          <Button className={styles.button} onClick={showEditCategoryModal}>
            <EditIcon className={styles.icon} />
          </Button>

          <Button className={styles.button} onClick={showDeleteCategoryModal}>
            <DeleteIcon className={styles.icon} />
          </Button>
        </div>
      )}

      <Link className={styles.catalog__link} to={`${name}`}>
        <div className={styles.catalog__card}>
          <img className={styles.catalog__img} src={image || '/img/no_photo.webp'} alt='logo' />
          <div className={styles.catalog__title}>
            <h3 className={styles.h3}>{title}</h3>
          </div>
        </div>
      </Link>
    </li>
  );
};
