import { object, string } from 'yup';

export const xmlImportSchema = object().shape({
  id: string().required('id - обязательное поле'),
  title: string().required('Название - обязательное поле'),
  price: string().required('Цена - обязательное поле'),
  stock: string(),
  image: string().required('Картинка - обязательное поле'),
  additionalImages: string(),
  description: string(),
  discount: string(),
  characteristics: string(),
  peculiarities: string(),
  subCategoryName: string().required('Подкатегория - обязательное поле'),
  subCategoryId: string().required('Id подкатегории - обязательное поле'),
  link: string().required('Ссылка - обязательное поле'),
});
