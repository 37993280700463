import React from 'react';
import { Modal } from '../Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInput } from '../Input';
import { subCategoryParameter } from '../../shared';
import classes from './SubCategoryParameterModal.module.scss';
import { ErrorMessage } from '../ErrorMessage';
import { useCreateParameter, useUpdateParameter } from '../../store';
import { Loader } from '../loader/loader';

export const SubCategoryParameterModal = ({ defaultValues, subCategory, onClose }) => {
  const [createParameter, createLoading, _, createError] = useCreateParameter();
  const [updateParameter, updateLoading, __, updateError] = useUpdateParameter();

  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(subCategoryParameter),
    mode: 'all',
  });

  const onSubmit = (values) => {
    if (defaultValues?.id) {
      updateParameter({ ...values, subCategoryName: subCategory }, onClose);
    } else {
      createParameter({ ...values, subCategoryName: subCategory }, onClose);
    }
  };

  return (
    <Modal
      onClose={onClose}
      submitBtnName='Сохранить'
      formId='subCategoryParameterForm'
      disableSubmit={createLoading || updateLoading}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='subCategoryParameterForm'
        className={classes.form}
      >
        {(createError || updateError) && <ErrorMessage message={createError || updateError} />}

        <ControlledInput
          name='title'
          control={control}
          label='Отображаемое название'
          labelClass={classes.label}
        />

        {(createLoading || updateLoading) && <Loader />}
      </form>
    </Modal>
  );
};
