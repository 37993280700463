import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [],
};

const cartSlice = createSlice({
  initialState,
  name: 'cart',
  reducers: {
    toggleCartItem: (state, { payload }) => {
      const productIndex = state.cartItems.findIndex((p) => p.id === payload.id);
      if (productIndex !== -1) {
        state.cartItems.splice(productIndex, 1);
      } else {
        state.cartItems.push(payload);
      }
    },

    updateCartItem: (state, { payload }) => {
      const productIndex = state.cartItems.findIndex((p) => p.id === payload.id);

      if (productIndex !== -1) {
        state.cartItems[productIndex] = { ...state.cartItems[productIndex], ...payload };
      }
    },

    resetCart: (state) => {
      state.cartItems = [];
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const { toggleCartItem, updateCartItem, resetCart } = cartSlice.actions;
