import React, { useCallback } from 'react';
import styles from './SearchProduct.module.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ModalService, formatPrice, getPriceWithDiscount } from '../../shared';
import { useChangeSearch, useSelectProductsSearch } from '../../store';
import debounce from 'lodash.debounce';
import { getProductsByTitle } from '../../api';
import { Skeleton } from '../Skeleton';
import Popover from '../Popover/Popover';
import { useMedia } from 'use-media';
import { SearchModal } from './SearchModal/SearchModal';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchProduct } from './SearchProduct/SearchProduct';

const placeholders = new Array(4).fill(0);

export default function SearchProducts() {
  const navigate = useNavigate();
  const productsSearch = useSelectProductsSearch();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [searchInputElement, setSearchInputElement] = useState(null);
  const isMobile = useMedia({ maxWidth: 1000 });

  const changeSearch = useChangeSearch();

  const handleDebouncedSearch = debounce(findProducts, 500);

  async function findProducts(search) {
    setShowPopover(true);
    changeSearch(search);
    if (search) {
      try {
        setLoading(true);
        const { data } = await getProductsByTitle(search);
        setProducts(data);
      } catch {
        setProducts([]);
      } finally {
        setLoading(false);
      }
    } else {
      setProducts([]);
    }
  }

  const moveToProduct = (id) => {
    navigate(`/cardProduct/${id}`);
    changeSearch('');
  };

  const clickOnSearch = () => {
    if (isMobile) {
      ModalService.open(SearchModal, {});
    } else if (products.length && productsSearch) {
      setShowPopover(true);
    }
  };

  return (
    <div className={styles.search}>
      <form className={styles.search__block}>
        <SearchInput
          onChange={handleDebouncedSearch}
          ref={setSearchInputElement}
          onClick={clickOnSearch}
          onClear={() => setLoading(false)}
          defaultValue={productsSearch}
          wrapperClassName={styles.searchWrapper}
        />
      </form>

      {!isMobile && (
        <div>
          <div className={`${styles.list} list-reset`}>
            {showPopover && productsSearch && (
              <Popover
                reference={searchInputElement}
                placement='bottom-start'
                onClose={() => setShowPopover(false)}
                popoverWrapperClass='popover__transparent-wrapper'
              >
                <ul className={`${styles.list} list-reset`}>
                  {loading &&
                    placeholders.map((_, idx) => (
                      <li key={idx}>
                        <Skeleton className={styles.placeholder} />
                      </li>
                    ))}

                  {!loading &&
                    products.map((product) => (
                      <SearchProduct key={product.id} {...product} onClick={moveToProduct} />
                    ))}

                  {!loading && !products.length && (
                    <li className={styles.noProducts}>
                      Товары не найдены, пожалуйста введите другое название
                    </li>
                  )}
                </ul>
              </Popover>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
