import React from 'react';
import { Button } from '../Button';
import { Portal } from '../Portal';
import classes from './modal.module.scss';

import { ReactComponent as CloseIcon } from '../../assets/close.svg';

export function Modal({
  formId,
  children,
  onClose,
  onSubmit,
  submitBtnName,
  error,
  contentWrapperClassName,
  footer,
  disableSubmit,
}) {
  return (
    <Portal>
      <div
        role='textbox'
        tabIndex={0}
        className={`${classes.modal} ${classes.active}`}
        onKeyPress={(e) => e.stopPropagation()}
      >
        <div
          role='textbox'
          tabIndex={0}
          className={`${classes.modal__content} ${contentWrapperClassName || ''} ${classes.active}`}
          onMouseDown={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
        >
          <Button className={classes.modal__header} appearance='transparent'>
            <CloseIcon
              onClick={onClose}
              onKeyPress={(e) => e.stopPropagation()}
              className={classes.modal__close}
            />
          </Button>
          {error && <p className={classes.modalErrorText}>{error.message}</p>}

          <div className={classes.content}>{children}</div>

          {!footer && (
            <div className={classes.modal__footer}>
              <Button className={classes.button__cancel} onClick={onClose}>
                Отмена
              </Button>
              <Button
                onClick={onSubmit}
                className={classes.button__submit}
                type='submit'
                form={formId}
                disabled={disableSubmit}
              >
                {submitBtnName}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
}
