import React from "react";
import styles from "./NotFoundPage.module.scss";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <section className={styles.notFoundPage}>
      <div className="container">
        <h1>Неверный адрес или ссылка</h1>
        <Link to={"/"}>Перейти на главную страницу</Link>
      </div>
    </section>
  );
}
