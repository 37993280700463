import cn from './loader.module.scss';

export function Loader() {
  return (
    <div className={cn.root}>
      <div className={cn.loader}>
        <svg
          id='227c182e-ef44-44e9-bcdf-1d78e83e1389'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 429.42 429.55'
        >
          <path
            d='M183.9,13.6C140.7,21.1,99.6,40.8,66.6,74.4,27.1,114.4,6.9,166.7,5.3,219L80,207.8a140.25,140.25,0,0,1,37.3-79.5C131,92.7,152.2,50.2,183.9,13.6Z'
            transform='translate(-5.3 -10.25)'
            fill='#43affd'
          />
          <path
            d='M297.9,107.4A450.2,450.2,0,0,1,388.8,92a161.7,161.7,0,0,0-18.1-20.3C326.4,28.5,268.8,8.3,211.1,10.4c-28.3,28.3-49.1,62-63.8,93.8a141.15,141.15,0,0,1,150.6,3.2Z'
            transform='translate(-5.3 -10.25)'
            fill='#43affd'
            opacity='0.4'
            style={{ isolation: 'isolate' }}
          />
          <path
            d='M361,225a140.67,140.67,0,0,1-9.8,51.7,632.39,632.39,0,0,1,64.2,37.7A215.22,215.22,0,0,0,401,109.6c-32.3,1.9-60.7,6.3-85,12.2A140.29,140.29,0,0,1,361,225Z'
            transform='translate(-5.3 -10.25)'
            fill='#43affd'
            opacity='0.7'
            style={{ isolation: 'isolate' }}
          />
          <path
            d='M238.4,364.8l-9.7,75a214.54,214.54,0,0,0,144.6-64A227.1,227.1,0,0,0,406.9,331a501.1,501.1,0,0,0-63.5-37.7A140.88,140.88,0,0,1,238.4,364.8Z'
            transform='translate(-5.3 -10.25)'
            fill='#43affd'
          />
          <circle
            id='bf444c64-dcfc-415b-9d3b-9fcdbbcce372'
            data-name='&lt;Path&gt;'
            cx='214.7'
            cy='214.75'
            r='76.8'
            fill='#43affd'
          />
        </svg>
      </div>
    </div>
  );
}
