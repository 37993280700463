import React from 'react';
import styles from '../SearchProduct.module.scss';
import { formatPrice, getPriceWithDiscount } from '../../../shared';

export const SearchProduct = ({ onClick, ...product }) => {
  return (
    <li className={styles.product} key={product.id} onClick={() => onClick(product.id)}>
      <div className={styles.imageWrapper}>
        <img
          width={100}
          height={100}
          src={product.image || '/img/no_photo.webp'}
          alt={product.title}
          className={styles.productImage}
        />
      </div>

      <div className={styles.productInner}>
        <p className={styles.productName}>{product.title}</p>

        <p style={{ color: product.discount > 0 ? '#daa520' : '#33cc00' }}>
          {`${formatPrice(getPriceWithDiscount(product.price, product.discount))}  ₽ `}

          {!!product.discount && (
            <span className={styles.price__crossOut}>{formatPrice(product.price)} ₽</span>
          )}
        </p>
      </div>

      <div className={styles.productStockWrapper}>
        <span className={styles.stock}>{product.stock > 0 ? `В наличии` : 'В пути'}</span>
        {product.discount !== 0 && (
          <div className={styles.discount}>{`- ${product.discount} %`}</div>
        )}
      </div>
    </li>
  );
};
