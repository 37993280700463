import { createSelector } from '@reduxjs/toolkit';

const selectProductsState = createSelector(
  (state) => state,
  (state) => state.productsState
);

export const selectProducts = createSelector(selectProductsState, (state) => state.products);

export const selectProductsSearch = createSelector(selectProductsState, (state) => state.search);

export const selectFilteredProducts = createSelector(
  selectProductsState,
  selectProductsSearch,
  (state, search) =>
    state.products.filter((product) => product.title.toUpperCase().startWidth(search.toUpperCase()))
);
