import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './Subcategories.module.scss';
import {
  useDeleteSubCategory,
  useGetCategoryByName,
  useGetSubCategories,
  useSelectAuthorized,
  useSelectCategoryByName,
  useSelectSubCategories,
} from '../../store';
import { SubCategoryModal } from '../../components/SubCategoryModal';
import { Button } from '../../components/Button';
import { ModalService } from '../../shared';
import { SubCategory } from './SubCategory';
import { Skeleton } from '../../components/Skeleton';

const placeholders = new Array(8).fill(0);

export function SubCategories() {
  const params = useParams();

  const [getSubCategories, loadingSubCategories, _, error] = useGetSubCategories(params.category);
  const [getCategory] = useGetCategoryByName();
  const deleteSubCategory = useDeleteSubCategory();

  const subCategories = useSelectSubCategories(params.category);
  const category = useSelectCategoryByName(params.category);
  const isAuthorized = useSelectAuthorized();

  useEffect(() => {
    getSubCategories();
    document.documentElement.scrollTop = 0;

    if (!category) {
      getCategory(params.category);
    }
  }, []);

  const showSubCategoryModal = () => {
    ModalService.open(SubCategoryModal, {
      category: params.category,
    });
  };

  if (error) {
    return (
      <section className={styles.subcategories}>
        <h4 className={styles.errorText}>{error}</h4>
      </section>
    );
  }

  return (
    <section className={styles.subcategories}>
      <div className='container'>
        <ul className='breadcrumb list-reset'>
          <li>
            <Link to={'/'}>Главная</Link>
          </li>
          {category && <li>{category.title}</li>}
        </ul>

        {category && <h1 className={styles.h1}>{category.title}</h1>}

        {isAuthorized && (
          <div className={styles.addSubCategoryBlock}>
            <Button onClick={showSubCategoryModal}>Добавить подкатегорию</Button>
          </div>
        )}

        <div className={styles.subcategories__block}>
          <ul className={`${styles.catalog__list} list-reset`}>
            {loadingSubCategories &&
              placeholders.map((_, idx) => <Skeleton key={idx} className={styles.placeholder} />)}

            {!loadingSubCategories &&
              subCategories.map((subCategory) => (
                <SubCategory
                  key={subCategory.id}
                  {...subCategory}
                  isAuthorized={isAuthorized}
                  onDelete={deleteSubCategory}
                />
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
