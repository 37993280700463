import { createSelector } from '@reduxjs/toolkit';

const selectFavoritesState = createSelector(
  (state) => state,
  (state) => state.favoritesState
);

export const selectFavorites = createSelector(selectFavoritesState, (state) => state.favorites);

export const selectIsFavorite = (id) =>
  createSelector(selectFavoritesState, (state) => state.favorites.find((p) => p.id === id));
