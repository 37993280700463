import React from "react";
import styles from "./SharkFin.module.scss";

export default function SharkFin() {
  return (
    <section className={styles.hero}>
      <div className={styles.hero__content}>
        <h1 className={styles.h1}>shark pro</h1>
        <p className={styles.hero__span}>
          Оборудование и инструменты для профессионалов
        </p>
        <div className={styles.marquee}>
          <svg
            width="450"
            height="360"
            viewBox="0 0 119.0625 95.249994"
            version="1.1"
            id="svg1"
          >
            <defs id="defs1" />
            <g id="layer1">
              <g id="g2" transform="translate(-44.976827,-74.109009)">
                <path
                  style={{
                    fill: "#ff0000",
                    stroke: "#ff0000",
                    strokeWidth: "2.64583",
                    strokeLinejoin: "round",
                  }}
                  d="M 48.65113,167.8464 H 149.73737 C 102.43592,141.38528 186.02084,70.893132 154.09471,76.840803 104.53492,86.073532 55.119971,122.55433 48.65113,167.8464 Z"
                  id="path1"
                />
                <path
                  style={{
                    fill: "#ffffff",
                    stroke: "none",
                    strokeWidth: "2.64583",
                    strokeLinejoin: "round",
                  }}
                  d="m 150.98455,106.26255 -14.52509,3.63127 14.52509,-1.33784 z"
                  id="path2"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </section>
  );
}
