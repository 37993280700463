import React, { useState } from 'react';
import { Modal } from '../../Modal';
import styles from './SearchModal.module.scss';
import { SearchInput } from '../../SearchInput/SearchInput';
import { useNavigate } from 'react-router-dom';
import { useChangeSearch, useSelectProductsSearch } from '../../../store';
import debounce from 'lodash.debounce';
import { getProductsByTitle } from '../../../api';
import { Skeleton } from '../../Skeleton';
import { SearchProduct } from '../SearchProduct/SearchProduct';
import { useFocusWhen } from '../../../hooks/use-focus-when';

const placeholders = new Array(4).fill(0);

export const SearchModal = ({ onClose }) => {
  const navigate = useNavigate();
  const productsSearch = useSelectProductsSearch();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useFocusWhen();
  const changeSearch = useChangeSearch();

  const handleDebouncedSearch = debounce(findProducts, 500);

  async function findProducts(search) {
    changeSearch(search);
    if (search) {
      try {
        setLoading(true);
        const { data } = await getProductsByTitle(search);
        setProducts(data);
      } catch {
        setProducts([]);
      } finally {
        setLoading(false);
      }
    } else {
      setProducts([]);
    }
  }

  const moveToProduct = (id) => {
    navigate(`/cardProduct/${id}`);
    changeSearch('');
    onClose();
  };

  return (
    <Modal contentWrapperClassName={styles.modal} onClose={onClose} footer={<div />}>
      <SearchInput
        onChange={handleDebouncedSearch}
        onClear={() => setLoading(false)}
        defaultValue={productsSearch}
        wrapperClassName={styles.input}
        ref={inputRef}
      />

      <ul className={`${styles.list} list-reset`}>
        {loading &&
          placeholders.map((_, idx) => (
            <li key={idx}>
              <Skeleton className={styles.placeholder} />
            </li>
          ))}

        {!loading &&
          products.map((product) => (
            <SearchProduct
              key={product.id}
              {...product}
              onClick={() => moveToProduct(product.id)}
            />
          ))}

        {!loading && !products.length && productsSearch && (
          <li className={styles.noProducts}>
            Товары не найдены, пожалуйста введите другое название
          </li>
        )}
      </ul>
    </Modal>
  );
};
