import React from 'react';
import styles from './CountProducts.module.scss';

function isNumber(obj) {
  return typeof obj === 'number' && !isNaN(obj);
}

const trimLeadingZeros = (value) => {
  if (value && isNumber(+value)) {
    return value.toString().replace(/^(-)?0+(?=\d)/, '$1');
  }

  return +value;
};

export default function CountProducts({ updateCartItem, product }) {
  return (
    <div className={styles.cart__quantity}>
      <button
        onClick={() => {
          updateCartItem({
            id: product.id,
            count: Math.max(0, product.count - 1),
          });
        }}
        className={` ${styles.cart__btn__min} btn-reset`}
      >
        <svg
          width={22}
          height={22}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='12' cy='12' r='10' stroke='#1C274C' strokeWidth='1.5' />
          <path d='M15 12H9' stroke='#1C274C' strokeWidth='1.5' strokeLinecap='round' />
        </svg>
      </button>
      <input
        type='number'
        name='productСount'
        value={trimLeadingZeros(product.count)}
        disabled=''
        onChange={(e) => {
          updateCartItem({ id: product.id, count: Math.max(+e.target.value, 0) });
        }}
      />
      <button
        onClick={() => updateCartItem({ id: product.id, count: product.count + 1 })}
        className={` ${styles.cart__btn__plus} btn-reset`}
      >
        <svg
          fill='#000000'
          width={20}
          height={20}
          viewBox='0 0 32 32'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM23 15h-6v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1v6h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1h6v6c0 0.552 0.448 1 1 1s1-0.448 1-1v-6h6c0.552 0 1-0.448 1-1s-0.448-1-1-1z'></path>
        </svg>
      </button>
    </div>
  );
}
