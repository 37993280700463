export const getValuesForSubmit = (form, values, subCategoryName) => {
  const formData = new FormData(form);

  if (values.id) {
    formData.append('id', values.id);
  }

  if (!values.discount) {
    formData.delete('discount');
    formData.append('discount', 0);
  }

  formData.append('subCategoryName', subCategoryName);

  formData.delete('peculiarities');
  values.peculiarities
    .split('\n')
    .forEach((peculiarity) => formData.append('peculiarities', peculiarity));

  formData.delete('characteristics');
  values.characteristics
    .split('\n')
    .forEach((characteristic) => formData.append('characteristics', characteristic));

  // if image was changed
  if (typeof values.image !== 'string') {
    formData.append('image', values.image);
  }

  // if image was changed
  const isAdditionalImagesChanged = values?.additionalImages?.some(
    (image) => typeof image !== 'string'
  );
  if (isAdditionalImagesChanged) {
    values?.additionalImages?.forEach((image) => {
      formData.append('additionalImages', image);
    });
  }

  if (!values?.additionalImages?.length) {
    formData.append('additionalImages', null);
  }

  values?.parameters?.forEach((parameter) => {
    formData.append('parameters', parameter.id);
  });

  // i don`t know what is it
  formData.delete('search_name_input');

  return formData;
};
