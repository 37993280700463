import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCube, Pagination, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cube';
import styles from './Swiper.module.scss';
import slides from './data/slides';
import { Link } from 'react-router-dom';

export default function SwiperApp() {
  return (
    <div className='container'>
      <div className={styles.swiperBody}>
        <Swiper
          className={styles.swiper}
          modules={[Navigation, EffectCube, Autoplay, Pagination, A11y]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          effect={'cube'}
          grabCursor={true}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {slides.map((slide) => (
            <SwiperSlide className={styles.swiper__slide} key={slide.id}>
              <Link to={slide.url}>
                <img className={styles.img} src={slide.img} alt={slide.img} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
