export function toDictionary(items, keySelector, valueSelector) {
  const objectMap = {};

  if (!items) {
    return {};
  }

  if (!keySelector) {
    throw new Error('Key selector is not defined.');
  }

  if (!valueSelector) {
    valueSelector = function (item) {
      return item;
    };
  }

  for (let i = 0; i < items.length; i++) {
    const key = keySelector(items[i], i);
    if (!Object.prototype.hasOwnProperty.call(objectMap, key)) {
      objectMap[key] = valueSelector(items[i], i);
    }
  }

  return objectMap;
}
