import React from 'react';
import styles from './Favorites.module.scss';
import { Link } from 'react-router-dom';
import {
  useSelectCartItems,
  useSelectFavorites,
  useToggleCartItem,
  useToggleFavorite,
} from '../../store';
import { formatPrice, getPriceWithDiscount } from '../../shared';

export default function Favorites() {
  const favoriteItems = useSelectFavorites();
  const cartItems = useSelectCartItems();

  const toggleFavorite = useToggleFavorite();
  const toggleCartItem = useToggleCartItem();

  return (
    <section className={styles.favorites}>
      <div className='container'>
        <div>
          <ul className='breadcrumb list-reset'>
            <li>
              <Link to={'/'}>Главная</Link>
            </li>
            <li>Избранное</li>
          </ul>
          <h2>Избранное</h2>
          <ul className={`${styles.list} list-reset`}>
            {favoriteItems.length === 0 && (
              <Link to={'/catalog'} className={styles.no__favorit}>
                <div>
                  <p>Вы ещё не добавили товары в избранное &#128148;</p>
                </div>
                <div>
                  <span>Нажми на меня чтобы вернуться к выбору товара</span>
                </div>
              </Link>
            )}

            {favoriteItems.map((products) => {
              function addToBasket(cartItems) {
                return !!cartItems.find((el) => el.id === products.id);
              }
              const addBasket = addToBasket(cartItems);
              return (
                <li key={products.id} className={styles.item}>
                  <div className={styles.card}>
                    <div className={styles.card__img}>
                      <img src={products.image || '/img/no_photo.webp'} alt={products.title} />
                    </div>

                    <div className={styles.title}>
                      <h3>{products.title}</h3>
                    </div>

                    <div className={styles.price}>
                      {/* <b>Цена:</b> */}
                      <span
                        className={styles.fw}
                        style={{ color: products.discount === 0 || '#daa520' }}
                      >
                        {formatPrice(getPriceWithDiscount(products.price, products.discount))} ₽
                      </span>

                      {products.discount === 0 || (
                        <span className={styles.price__crossOut}>
                          &#160;{formatPrice(products.price)} ₽
                        </span>
                      )}
                    </div>

                    <div className={styles.btn}>
                      <button
                        onClick={() => toggleCartItem(products)}
                        // className={`${styles.btn__add} btn-reset`}
                        className={`${
                          addBasket ? styles.btn__add : styles.btn__add__yes
                        } btn-reset`}
                      >
                        {addBasket ? 'Добавленно' : 'Добавить в корзину'}
                        {/* Добавить в корзину */}
                      </button>
                      <button
                        onClick={() => toggleFavorite(products)}
                        className={`${styles.btn__delete} btn-reset`}
                      >
                        Удалить
                      </button>
                    </div>
                  </div>
                  <span className={styles.stock}>
                    {products.stock === 0 ? 'В пути' : `В наличии`}
                  </span>
                  {products.discount === 0 || (
                    <span className={styles.discount}>- {products.discount} %</span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
}
