import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCategory,
  deleteCategory,
  getCategories,
  getCategoryByName,
  updateCategory,
} from '../../api';

export const getCategoriesAction = createAsyncThunk(
  'category/get categories',
  async (_, thunkAPI) => {
    try {
      const response = await getCategories();

      return response.data;
    } catch (e) {
      throw new Error(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getCategoryAction = createAsyncThunk(
  'category/get category',
  async (payload, thunkAPI) => {
    try {
      const response = await getCategoryByName(payload);

      return response.data;
    } catch (e) {
      throw new Error(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createCategoryAction = createAsyncThunk('category/create category', async (data) => {
  try {
    const response = await createCategory(data);

    return response.data;
  } catch (e) {
    throw Error(
      JSON.stringify({
        code: e.response.status,
        message: e.message,
      })
    );
  }
});

export const updateCategoryAction = createAsyncThunk('category/update category', async (data) => {
  try {
    const response = await updateCategory(data);

    return response.data;
  } catch (e) {
    throw Error(
      JSON.stringify({
        code: e.response.status,
        message: e.message,
      })
    );
  }
});

export const deleteCategoryAction = createAsyncThunk(
  'category/delete category',
  async (id, thunkAPI) => {
    try {
      const response = await deleteCategory(id);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
