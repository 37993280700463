import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favorites: [],
};

const favoritesSlice = createSlice({
  initialState,
  name: 'favorites',
  reducers: {
    toggleFavorite: (state, { payload }) => {
      const productIndex = state.favorites.findIndex((p) => p.id === payload.id);

      if (productIndex !== -1) {
        state.favorites.splice(productIndex, 1);
      } else {
        state.favorites.push(payload);
      }
    },
  },
});

export const favoritesReducer = favoritesSlice.reducer;
export const { toggleFavorite } = favoritesSlice.actions;
