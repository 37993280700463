import { createSelector } from '@reduxjs/toolkit';

const selectSubCategoriesState = createSelector(
  (state) => state,
  (state) => state.subCategoriesState
);

export const selectSubCategories = createSelector(
  selectSubCategoriesState,
  (state) => state.subCategories
);

export const selectSubCategory = (name) =>
  createSelector(selectSubCategories, (subCategories) =>
    subCategories.find((c) => c.name === name)
  );

export const selectProductById = (id) =>
  createSelector(selectSubCategories, (subCategories) =>
    subCategories.find((subCategory) => subCategory.products.find((p) => p.id === id))
  );
