import React from 'react';
import { Modal } from '../Modal';
import classes from './DeleteModal.module.scss';

export const DeleteModal = ({ name, text, onSubmit, onClose }) => {
  const deleteItem = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      submitBtnName='Удалить'
      onSubmit={deleteItem}
      contentWrapperClassName={classes.modal}
    >
      <h3>Удалить {name}?</h3>
      <p className={classes.text}>{text}</p>
    </Modal>
  );
};
