import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './Products.module.scss';
import ParamsBtn from '../../components/ParamsBtn/ParamsBtn';
import { useState } from 'react';
import { Product } from '../../components/Product';
import { Button } from '../../components/Button';
import { SubCategoryParameterModal } from '../../components/SubCategoryParameterModal/SubCategoryParameterModal';
import { ModalService } from '../../shared';
import {
  useDeleteParameter,
  useDeleteProduct,
  useGetSubCategory,
  useSelectAuthorized,
  useSelectCartItems,
  useSelectFavorites,
  useSelectSubCategory,
  useToggleCartItem,
  useToggleFavorite,
} from '../../store';
import { getSortedProducts } from './getSortedProducts';
import { Skeleton } from '../../components/Skeleton';
import { XmlImportModal } from '../../components/XmlImportModal';
import { ProductModal } from '../../components/ProductModal';

const productsPlaceholders = new Array(8).fill(0);
const parameterPlaceholders = new Array(2).fill(0);

export function Products() {
  const params = useParams();
  const [sortingParams, setSortingParams] = useState([]);
  const [sortOrder, setSortOrder] = useState(true);
  const isAuthorized = useSelectAuthorized();
  const favoriteItems = useSelectFavorites();
  const cartItems = useSelectCartItems();
  const subCategory = useSelectSubCategory(params.subCategory);

  const [getSubCategory, loading, _, error] = useGetSubCategory(params.subCategory, !subCategory);
  const toggleFavorite = useToggleFavorite();
  const toggleCartItem = useToggleCartItem();
  const deleteParameter = useDeleteParameter();
  const deleteProduct = useDeleteProduct();

  const sortedProducts = useMemo(
    () => getSortedProducts(subCategory?.products, sortingParams, sortOrder),
    [subCategory?.products, sortingParams, sortOrder]
  );

  useEffect(() => {
    if (!subCategory) {
      getSubCategory();
    }
  }, []);

  function toggleProductParam(paramId) {
    setSortingParams((prevState) => {
      const paramIndex = prevState.findIndex((param) => param === paramId);
      if (paramIndex !== -1) {
        const copy = [...prevState];
        copy.splice(paramIndex, 1);
        return copy;
      } else {
        return [...prevState, paramId];
      }
    });
  }

  const showParameterModal = () => {
    ModalService.open(SubCategoryParameterModal, {
      subCategory: params.subCategory,
    });
  };

  const showProductModal = () => {
    ModalService.open(ProductModal, {
      allParameters: subCategory?.parameters || [],
      subCategoryName: params.subCategory,
    });
  };

  const showXmlImportModal = () => {
    ModalService.open(XmlImportModal, {
      subCategory: params.subCategory,
    });
  };

  return (
    <section className={styles.subcategories}>
      <div className='container'>
        <ul className='breadcrumb list-reset'>
          <li>
            <Link to={'/'}>Главная</Link>
          </li>
          <li>{subCategory?.title}</li>
        </ul>

        <h1 className={styles.h1}>{subCategory?.title}</h1>

        <div className={styles.resultsStrip}>
          <div className={styles.displayResults__right}>
            <span className={styles.displayResults}>Цены по:</span>
            <button
              onClick={() => setSortOrder(true)}
              className={`${styles.btn} btn-reset btn-anim`}
            >
              Возрастанию
            </button>
            <button
              onClick={() => setSortOrder(false)}
              className={`${styles.btn} btn-reset btn-anim`}
            >
              Убыванию
            </button>
          </div>

          {isAuthorized && <Button onClick={showXmlImportModal}>Импортировать xml</Button>}
          {isAuthorized && <Button onClick={showProductModal}>Добавить товар</Button>}
        </div>

        <div className={styles.subcategoriesSelection}>
          {error && <h4 className={styles.errorText}>Не удалось загрузить параметры.</h4>}

          <ul className={`${styles.subcategoriesSelection__list} list-reset`}>
            {subCategory?.parameters?.map((parameter) => {
              return (
                <ParamsBtn
                  key={parameter.id}
                  id={parameter.id}
                  title={parameter.title}
                  onClick={toggleProductParam}
                  onDelete={deleteParameter}
                  isAuthorized={isAuthorized}
                  subCategory={params.subCategory}
                />
              );
            })}

            {loading &&
              parameterPlaceholders.map((_, idx) => (
                <Skeleton key={idx} className={styles.parameterSkeleton} />
              ))}

            {isAuthorized && (
              <Button className={styles.addParamsButton} onClick={showParameterModal}>
                Добавить параметр
              </Button>
            )}
          </ul>
        </div>

        <div className={styles.products}>
          {error && <h4 className={styles.errorText}>Не удалось загрузить товары.</h4>}

          <ul className={`${styles.products__list}  list-reset`}>
            {!sortedProducts.length && !loading && !error && (
              <Link to={'/catalog'} className={styles.no__product}>
                <h4>Товар с указанными характеристиками отсутствует.</h4>
                <p>
                  Мы работаем над расширением нашего ассортимента и скоро будем готовы вас
                  порадовать новыми товарами! &#128579;
                </p>
                <p>Нажми на меня чтобы вернуться к выбору товара</p>
              </Link>
            )}

            {loading &&
              productsPlaceholders.map((_, idx) => (
                <li key={idx}>
                  <Skeleton className={styles.productSkeleton} />
                </li>
              ))}

            {sortedProducts.map((product) => {
              return (
                <Product
                  key={product.id}
                  isFavorite={!!favoriteItems.find((el) => el.id === product.id)}
                  isCart={!!cartItems.find((el) => el.id === product.id)}
                  toggleFavorite={toggleFavorite}
                  onAddCart={toggleCartItem}
                  product={product}
                  isAuthorized={isAuthorized}
                  parameters={subCategory?.parameters || []}
                  subCategoryName={params.subCategory}
                  onDelete={deleteProduct}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
}
