import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRefreshToken, useSetAuthorized } from '../../store';

export const MainOutlet = () => {
  const [refreshToken, loading] = useRefreshToken();
  const setAuthorized = useSetAuthorized();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      refreshToken();
    } else {
      setAuthorized(false);
    }
  }, []);

  if (loading) {
    return <p>...loading</p>;
  }

  return <Outlet />;
};
