export const apiPath = {
  login: '/login',
  refreshToken: '/refresh',
  categories: '/categories',
  subCategories: '/sub-categories',
  subCategoriesParameter: '/sub-category-parameter',
  products: '/products',
  uploadXml: '/upload-xml',
  sendEmail: '/send-email',
};
