import React from 'react';
import { Controller } from 'react-hook-form';
import cn from './Input.module.scss';

export const ControlledDropzone = ({ control, name, rootProps, inputProps, label, labelClass }) => {
  const labelClassName = labelClass ? `${cn.label} ${labelClass}` : cn.label;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className={labelClassName}>
          {label && <span className={cn.labelText}>{label}</span>}

          <div {...rootProps} className={cn.dropzone}>
            <input {...inputProps} />
            <p>Перетащите изображение или нажмите</p>
          </div>

          {!!error && <span className={cn.errorText}>{error.message || ''}</span>}
        </div>
      )}
    />
  );
};
