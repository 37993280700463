import { useSelector, useDispatch } from 'react-redux';

import { selectFavorites, selectIsFavorite } from './selectors';
import { toggleFavorite } from './slice';

export function useSelectFavorites() {
  return useSelector(selectFavorites);
}

export function useSelectIsFavorite(id) {
  return useSelector(selectIsFavorite(id));
}

export const useToggleFavorite = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(toggleFavorite(payload));
};
