import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthorized: false,
};

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setAuthorized: (state, { payload }) => {
      state.isAuthorized = payload;
    },
  },
});

export const userReducer = userSlice.reducer;
export const { setAuthorized } = userSlice.actions;
