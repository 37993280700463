import React from 'react';
import Catalog from '../../components/Catalog/Catalog';
import SharkFin from '../../components/SharkFin/SharkFin';
import SwiperApp from '../../components/Swiper/Swiper';

export default function HomePage() {
  return (
    <div>
      <SharkFin />
      <SwiperApp />

      <Catalog />
    </div>
  );
}
