import { createSelector } from '@reduxjs/toolkit';

const selectCategoriesState = createSelector(
  (state) => state,
  (state) => state.categoriesState
);

export const selectCategories = createSelector(selectCategoriesState, (state) => state.categories);

export const selectCategoryByName = (name) =>
  createSelector(selectCategories, (categories) =>
    categories.find((category) => category.name === name)
  );
