import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createProduct,
  createSubCategory,
  createSubCategoryParameter,
  deleteProduct,
  deleteSubCategory,
  deleteSubCategoryParameter,
  getSubCategories,
  getSubCategory,
  updateProduct,
  updateSubCategory,
  updateSubCategoryParameter,
} from '../../api';
import { uploadXml } from '../../api/requests/upload-xml';

export const getSubCategoriesAction = createAsyncThunk(
  'subCategory/get subCategories',
  async (payload, thunkAPI) => {
    try {
      const response = await getSubCategories(payload);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getSubCategoryAction = createAsyncThunk(
  'subCategory/get subCategory',
  async (payload, thunkAPI) => {
    try {
      const response = await getSubCategory(payload);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createSubCategoryAction = createAsyncThunk(
  'subCategory/create category',
  async (data) => {
    try {
      const response = await createSubCategory(data);

      return response.data;
    } catch (e) {
      throw Error(
        JSON.stringify({
          code: e.response.status,
          message: e.message,
        })
      );
    }
  }
);

export const updateSubCategoryAction = createAsyncThunk(
  'subCategory/update subCategory',
  async (data) => {
    try {
      const response = await updateSubCategory(data);

      return response.data;
    } catch (e) {
      throw Error(
        JSON.stringify({
          code: e.response.status,
          message: e.message,
        })
      );
    }
  }
);

export const deleteSubCategoryAction = createAsyncThunk(
  'subCategory/delete subCategory',
  async (id, thunkAPI) => {
    try {
      const response = await deleteSubCategory(id);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createParameterAction = createAsyncThunk(
  'Parameter/create parameter',
  async (data, thunkAPI) => {
    try {
      const response = await createSubCategoryParameter(data);

      return { ...response.data, subCategoryName: data.subCategoryName };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateParameterAction = createAsyncThunk(
  'Parameter/update parameter',
  async (data, thunkAPI) => {
    try {
      const response = await updateSubCategoryParameter(data);

      return { ...response.data, subCategoryName: data.subCategoryName };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteParameterAction = createAsyncThunk(
  'Parameter/delete Parameter',
  async (data, thunkAPI) => {
    try {
      const response = await deleteSubCategoryParameter(data.id);

      return { ...response.data, subCategoryName: data.subCategoryName };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createProductAction = createAsyncThunk(
  'Product/create Product',
  async (data, thunkAPI) => {
    try {
      const response = await createProduct(data);

      // data is FormData
      return { ...response.data, subCategoryName: data.get('subCategoryName') };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateProductAction = createAsyncThunk(
  'Product/update Product',
  async (data, thunkAPI) => {
    try {
      const response = await updateProduct(data);

      // data is FormData
      return {
        ...response.data,
        subCategoryName: data.get('subCategoryName'),
      };
    } catch (e) {
      throw e;
    }
  }
);

export const deleteProductAction = createAsyncThunk(
  'Product/delete Product',
  async (data, thunkAPI) => {
    try {
      const response = await deleteProduct(data.id);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const uploadXmlAction = createAsyncThunk('xml/upload xml', async (data, thunkAPI) => {
  try {
    const response = await uploadXml(data);

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
