import React, { useRef, useState } from 'react';
import styles from './Checkout.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { formatPrice, getPriceWithDiscount } from '../../shared';
import { useResetCart, useSelectCartItems } from '../../store';
import { sendEmail } from '../../api';

export default function Сheckout() {
  const navigate = useNavigate();

  const [sent, setSent] = useState(false);

  const ref = useRef();

  const cartItems = useSelectCartItems();

  const resetCart = useResetCart();

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Обязательно для заполнения';
    } else if (values.name.length > 80) {
      errors.name = 'Должно быть не более 80 символов.';
    }

    if (!values.phone) {
      errors.phone = 'Обязательно для заполнения';
    } else if (values.phone.length > 20) {
      errors.phone = 'Должно быть не более 20 символов.';
    }

    if (!values.email) {
      errors.email = 'Обязательно для заполнения';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Неверный адрес электронной почты';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
    validate,
    onSubmit: async (values) => {
      const formData = new FormData(ref.current);
      const data = {
        ...values,
        cart: formData.get('cart'),
      };

      try {
        await sendEmail(data);
        setSent(true);
        setTimeout(() => {
          setSent(false);
        }, 3000);
        resetCart();
        setTimeout(() => {
          navigate('/');
        }, 3500);
      } catch {}

      ref.current.reset();
    },
  });

  return (
    <div className='container'>
      {sent && (
        <div className={styles.sent}>
          <img width={20} height={20} src='./img/icons8-галочка-50.png' alt='Galka' />
          <p>Заявка отправлена</p>
        </div>
      )}

      <ul className='breadcrumb list-reset'>
        <li>
          <Link to={'/'}>Главная</Link>
        </li>
        <li>Оформление заказа</li>
      </ul>
      <h2 className={styles.h2}>Оформление заказа</h2>
      <p className={styles.desck}>
        Уважаемый клиент заполните форму с вашими данными и отправьте её нам. Наш менеджер свяжется
        с вами в ближайшее время для обсуждения заказа!
      </p>

      <div className={styles.form__block}>
        <form className={styles.form} onSubmit={formik.handleSubmit} ref={ref}>
          <label className={styles.label} htmlFor='name'>
            Ваше Имя
          </label>
          <div className={styles.input__groop}>
            <input
              className={styles.input}
              id='name'
              name='name'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.lastName}
              placeholder='Контактное лицо...'
            />
            {formik.errors.name ? <div className={styles.errors}>{formik.errors.name}</div> : null}
          </div>

          <label className={styles.label} htmlFor='phone'>
            Телефон
          </label>
          <div className={styles.input__groop}>
            <input
              className={styles.input}
              id='phone'
              name='phone'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.lastName}
              placeholder='Телефон для связи'
            />
            {formik.errors.Имя ? <div className={styles.errors}>{formik.errors.phone}</div> : null}
          </div>

          <label className={styles.label} htmlFor='email'>
            Email: (Электронная почта)
          </label>
          <div className={styles.input__groop}>
            <input
              className={styles.input}
              id='email'
              name='email'
              type='email'
              onChange={formik.handleChange}
              value={formik.values.lastName}
              placeholder='Ваша почта'
            />
            {formik.errors.email ? (
              <div className={styles.errors}>{formik.errors.email}</div>
            ) : null}
          </div>

          <label className={styles.label} htmlFor='message'>
            Сообщение
          </label>
          <div className={styles.input__groop}>
            <textarea
              className={`${styles.input} ${styles.textarea}`}
              id='message'
              name='message'
              type='text'
              onChange={formik.handleChange}
              // value={formik.values.lastName}
              value={formik.values.lastName}
              placeholder='Ваше сообщение...'
            />
            <textarea
              className={styles.product}
              id='Заказ'
              name='cart'
              type='text'
              onChange={formik.handleChange}
              value={cartItems.map((product) => {
                return `${product.title} Цена: ${getPriceWithDiscount(
                  product.count * product.price,
                  product.discount
                )} Скидка: ${product.discount}% кол-во: ${product.count} `;
              })}
              placeholder='Интересующий товар'
            />
          </div>

          <button className={`${styles.btn} btn-reset`} type='submit'>
            Оформить
          </button>
        </form>

        <div className={styles.form__info}>
          <h2 className={styles.h2}>Список ваших товаров:</h2>
          <ul className='list-reset'>
            {cartItems.map((product) => {
              return (
                <li className={styles.product__list} key={product.id}>
                  <p className={styles.product__title}>{product.title}</p>
                  <div className={styles.product__info}>
                    <span>
                      Цена:{' '}
                      {formatPrice(
                        getPriceWithDiscount(product.count * product.price, product.discount)
                      )}{' '}
                      ₽
                    </span>
                    <span>Скидка: {product.discount}%</span>
                    <span>Кол-во: {product.count}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
