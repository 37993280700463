import React from 'react';
import classes from './Button.module.scss';

export function Button({ children, icon, className, appearance = 'primary', ...props }) {
  const buttonTypeClassNames = {
    transparent: classes.transparent,
    primary: classes.primary,
  };

  const buttonTypeClassName = appearance ? buttonTypeClassNames[appearance] : '';

  return (
    <button
      className={`${classes.button} ${buttonTypeClassName} ${classes.button} ${className || ''}`}
      {...props}
    >
      {icon}
      <span>{children}</span>
    </button>
  );
}
