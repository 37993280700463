import { useEffect, useRef } from 'react';

export const useFocusWhen = (condition = true, deps = []) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && condition) {
      ref.current.focus();

      if (ref.current instanceof HTMLInputElement || ref.current instanceof HTMLTextAreaElement) {
        ref.current.setSelectionRange(ref.current.value.length, ref.current.value.length);
      }
    }
  }, deps);

  return ref;
};
