import React, { useEffect } from 'react';
import { ControlledDropzone } from '../../Input/ControlledDropzone';
import classes from '../ProductModal.module.scss';
import { useDropzone } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { ErrorMessage } from '../../ErrorMessage';
import { Button } from '../../Button';

const getImageUrl = (image, acceptedFile) => {
  if (typeof image === 'string') {
    return image;
  }

  if (acceptedFile) {
    return URL.createObjectURL(acceptedFile);
  }

  if (image?.path) {
    return URL.createObjectURL(image);
  }
};

export function AdditionalImages({ control, setValue }) {
  const additionalImages = useWatch({ control, name: 'additionalImages' });

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    onDrop: (files) => {
      setValue('additionalImages', files, {
        shouldValidate: true,
      });
    },
  });

  const deleteImage = (deleteIdx) => {
    const newImages = additionalImages.filter((_, idx) => idx !== deleteIdx);
    setValue('additionalImages', newImages);
  };

  return (
    <>
      <ControlledDropzone
        label='Дополнительные картинки'
        labelClass={classes.label}
        name='additionalImages'
        control={control}
        rootProps={getRootProps({ className: classes.dropzone })}
        inputProps={getInputProps()}
      />

      {!!fileRejections.length && <ErrorMessage message={'Максимальное кол-во изображений - 3'} />}

      {additionalImages &&
        additionalImages.map((image, idx) => (
          <div className={classes.imageBlock} key={typeof image === 'string' ? image : image.path}>
            <Button type='button' onClick={() => deleteImage(idx)}>
              Удалить картинку
            </Button>

            <img src={getImageUrl(image, acceptedFiles[idx])} alt='Изображение' />
          </div>
        ))}
    </>
  );
}
