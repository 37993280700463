import { apiPath } from '../apiPath';
import { $api } from '../configuration';

export const createSubCategory = (data) => {
  return $api.post(apiPath.subCategories, data, {
    headers: 'multipart/form-data',
  });
};

export const updateSubCategory = (data) => {
  return $api.put(apiPath.subCategories, data, {
    headers: 'multipart/form-data',
  });
};

export const deleteSubCategory = (id) => {
  return $api.delete(`${apiPath.subCategories}/${id}`);
};

export const getSubCategories = (categoryName) => {
  return $api.get(apiPath.subCategories, {
    params: {
      categoryName,
    },
  });
};

export const getSubCategory = (name) => {
  return $api(`${apiPath.subCategories}/${name}`);
};
