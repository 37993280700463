import React from 'react';
import styles from './Contacts.module.scss';
import { Link } from 'react-router-dom';

export default function Contacts() {
  return (
    <section className={styles.contacts}>
      <div className='container'>
        <ul className='list-reset'>
          <li className={styles.text}>
            <b>ИП Буриев Эркинджон Равшанджонович</b>
          </li>
          <li className={styles.text}>
            <b>Полное наименование организации:</b> Индивидуальный предприниматель Буриев Эркинджон
            Равшанджонович
          </li>
          <li className={styles.text}>
            <b>Юридический адрес:</b> 443117, Россия, Самарская обл, г Самара, переулок Керченский,
            д. 7
          </li>
          <li className={styles.text}>
            <b>Фактический адрес:</b> 443095, г. Самара, Московское шоссе, тер. 16 км, д. 1В,
            строение 1, секция 12, 1 этаж. ТЦ Авто Молл.
          </li>
          <li className={styles.text}>
            <b>ИНН:</b> 631814924753
          </li>
          <li className={styles.text}>
            <b>ОГРНИП:</b> 324632700028174
          </li>
          <li className={styles.text}>
            <b>ОКПО:</b> 2029934305
          </li>
          <li className={styles.subtitle}>
            <b>Банковские реквизиты</b>
          </li>
          <li className={styles.text}>
            <b>Банк:</b> ФИЛИАЛ "НИЖЕГОРОДСКИЙ" АО "АЛЬФА-БАНК"
          </li>
          <li className={styles.text}>
            <b>Р/счет:</b> 40802810729180012799
          </li>
          <li className={styles.text}>
            <b>Кор/счет:</b> 30101810200000000824
          </li>
          <li className={styles.text}>
            <b>БИК:</b> 042202824
          </li>
        </ul>
      </div>
    </section>
  );
}
