import { useEffect, useState } from 'react';
import { useSelectProductById } from '../../store';
import { usePromiseCbWithState } from '../../hooks';
import { getProduct } from '../../api';

export const useGetProduct = (productId) => {
  const productFromStore = useSelectProductById();
  const [product, setProduct] = useState(productFromStore);
  const [fetchProduct, loading] = usePromiseCbWithState(() => getProduct(productId), {
    defaultLoading: !productFromStore,
    error: 'Не удалось найти данный товар',
  });

  useEffect(() => {
    if (!productFromStore) {
      fetchProduct().then((response) => {
        if (response) {
          setProduct(response.data);
        }
      });
    }
  }, [productId]);

  return {
    loading,
    product,
  };
};
