import { useSelector, useDispatch } from 'react-redux';

import {
  createParameterAction,
  createProductAction,
  createSubCategoryAction,
  deleteParameterAction,
  deleteProductAction,
  deleteSubCategoryAction,
  getSubCategoriesAction,
  getSubCategoryAction,
  updateParameterAction,
  updateProductAction,
  updateSubCategoryAction,
  uploadXmlAction,
} from './actions';
import { selectProductById, selectSubCategories, selectSubCategory } from './selectors';
import { usePromiseCbWithState } from '../../hooks';

export function useSelectSubCategories() {
  return useSelector(selectSubCategories);
}

export function useSelectSubCategory(name) {
  return useSelector(selectSubCategory(name));
}

export function useSelectProductById(id) {
  return useSelector(selectProductById(id));
}

// subCategories
export const useGetSubCategories = (category) => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(() => dispatch(getSubCategoriesAction(category)).unwrap(), {
    defaultLoading: true,
    error: 'Не удалось загрузить подкатегории, попробуйте позже.',
  });
};

export const useGetSubCategory = (payload, defaultLoading) => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(() => dispatch(getSubCategoryAction(payload)).unwrap(), {
    defaultLoading,
    error: 'Ошибка',
  });
};

export const useCreateSubCategory = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(createSubCategoryAction(payload)).unwrap();
    afterAction();
  });
};

export const useUpdateSubCategory = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(updateSubCategoryAction(payload)).unwrap();
    afterAction();
  });
};

export const useDeleteSubCategory = () => {
  const dispatch = useDispatch();

  return (id) => dispatch(deleteSubCategoryAction(id));
};

// params

export const useCreateParameter = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(createParameterAction(payload)).unwrap();
    afterAction();
  });
};

export const useUpdateParameter = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(updateParameterAction(payload)).unwrap();
    afterAction();
  });
};

export const useDeleteParameter = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(deleteParameterAction(payload));
};

// products
export const useCreateProduct = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(createProductAction(payload)).unwrap();
    afterAction();
  });
};

export const useUpdateProduct = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(updateProductAction(payload)).unwrap();
    afterAction();
  });
};

export const useDeleteProduct = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(deleteProductAction(payload));
};

// xml
export const useUploadXml = () => {
  const dispatch = useDispatch();

  return usePromiseCbWithState(async (payload, afterAction) => {
    await dispatch(uploadXmlAction(payload)).unwrap();
    afterAction?.();
  });
};
