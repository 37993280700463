import { toDictionary } from '../../shared';

export const getSortedProducts = (products, activeParameterIds, sortOrder) => {
  if (!products) {
    return [];
  }

  let sortedProducts = [...products];

  if (activeParameterIds.length) {
    sortedProducts = sortedProducts.filter((product) => {
      const productParametersMap = toDictionary(product.parameters, (x) => x.id);

      for (const activeParameterId of activeParameterIds) {
        if (!productParametersMap[activeParameterId]) {
          return false;
        }
      }

      return true;
    });
  }

  sortedProducts.sort((a, b) => {
    if (sortOrder === true) {
      return a.price - b.price;
    } else {
      return b.price - a.price;
    }
  });

  return sortedProducts;
};
