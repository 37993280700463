import { FC, useEffect, useState } from 'react';

import { EventEmitter } from 'events';
import { sha512 } from 'js-sha512';
import { v4 as uuid } from 'uuid';

const deleteOnce = (arr, getter) => {
  return arr.filter((x) => !getter(x));
};

class LayoutModalServiceImpl {
  modals = {};

  modalsByTypeId = {};

  modalList = [];

  onChange = new EventEmitter();

  getModalTypeId(Builder) {
    return (Builder.displayName ? `${Builder.displayName}-` : '') + sha512(Builder.toString());
  }

  open(Builder, props = {}) {
    const id = uuid();
    let resolvefn = Promise.resolve;
    const typeId = this.getModalTypeId(Builder);

    const origFn = props.onClose;
    props.onClose = (args) => {
      this.unregisterModal(this.modals?.[id]);

      if (origFn) {
        origFn.call(this, args);
      }
      resolvefn(args);
    };

    const element = <Builder {...props} key={id} />;

    if (!element.props || typeof element.props.onClose !== 'function') {
      throw new Error('Invalid modal props implementation. See ModalProps type.');
    }

    const promise = new Promise((resolve) => {
      resolvefn = resolve;
    });

    const def = {
      id: id,
      typeId: typeId,
      close: promise,
      element: element,
      resolve: resolvefn,
    };

    this.registerModal(def);

    return def;
  }

  closeModalOfType(Builder) {
    const typeId = this.getModalTypeId(Builder);

    if (typeId in this.modalsByTypeId) {
      for (const item of this.modalsByTypeId[typeId]) {
        this.unregisterModal(item, { skipNotify: true });
      }
    }

    this.updateModalList();
    this.emitChange();
  }

  resolveModal(id) {
    if (id in this.modals) {
      this.modals[id].resolve();
      this.unregisterModal(this.modals[id]);
    }
  }

  unregisterModal(def, args) {
    this.modalList = deleteOnce(this.modalList, (m) => m === def);

    if (!def) {
      return;
    }

    if (this.modals[def.id]) {
      delete this.modals[def.id];
    }

    if (this.modalsByTypeId[def.typeId]) {
      this.modalsByTypeId[def.typeId] = deleteOnce(
        this.modalsByTypeId[def.typeId],
        (m) => m === def
      );

      if (!this.modalsByTypeId[def.typeId].length) {
        delete this.modalsByTypeId[def.typeId];
      }
    }

    if (!args?.skipNotify) {
      this.updateModalList();
      this.emitChange();
    }
  }

  registerModal(def, args) {
    this.modalList.push(def);

    if (!this.modalsByTypeId[def.typeId]) {
      this.modalsByTypeId[def.typeId] = [];
    }

    this.modals[def.id] = def;
    this.modalsByTypeId[def.typeId].push(def);

    if (!args?.skipNotify) {
      this.updateModalList();
      this.emitChange();
    }
  }

  emitChange() {
    this.onChange.emit('');
  }

  updateModalList() {
    this.modalList = Object.keys(this.modals).map((key) => this.modals[key]);
  }
}

export const ModalService = new LayoutModalServiceImpl();

export const Modal = () => {
  const [modals, setModals] = useState(ModalService.modalList);

  useEffect(() => {
    const handler = () => {
      setModals(ModalService.modalList);
    };

    ModalService.onChange.addListener('', handler);

    return () => {
      ModalService.onChange.removeListener('', handler);
    };
  }, []);

  return <>{modals.map((modal) => modal.element)}</>;
};
